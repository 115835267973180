import React, { useContext, useState } from "react";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  Grid,
  Header,
  Icon, 
  Modal,
  Popover,
  SpaceBetween,
  Table,
  Toggle
} from "@amzn/awsui-components-react";

import { useCollection } from "@amzn/awsui-collection-hooks";

import { IncidentLikelihoodSurveyResponse } from "src/types";
import EmptyState from "../../EmptyState";
import { SelectedSiteContext } from "../../useSelectedSite";
import { ModalContext } from "../../useModal";
import { LikelihoodSurveyContext } from "../../LikelihoodSurveyContext";
import { getMatchesCountText } from "../../../utils";
import { NOTES_MINIMUM_LENGTH } from "../../../constants"
import CancelWarning from "../../CancelWarning";
import { RiskContext } from "../../RiskProvider";
import LessonsLearned from "src/components/LessonsLearnedForm";
import SelectLikelihood from "src/components/SelectLikelihood";

const RiskRegisterModal = () => {
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [showChangesWarning, setShowChangesWarning] = useState(false);
  const [hasSeenUnsavedChangesWarning, setHasSeenUnsavedChangesWarning] = useState(false);
  const { hideModals, isShowing } = useContext(ModalContext);
  const { selectedEntity } = useContext(SelectedSiteContext);
  const { state, dispatch, postSurveyAnswers } = useContext(LikelihoodSurveyContext);
  const [showLessons, setShowLessons] = useState(false);
  const [selectedItems, setSelectedItems] = useState<IncidentLikelihoodSurveyResponse[]>([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { getFirstSubRiskId, getFullCategoryName } = useContext(RiskContext);

  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
  } = useCollection(state.assessedLikelihoodsByRisk, {
    filtering: {
      empty: (
        <EmptyState
          title="No Items Found"
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    sorting: {},
    selection: {},
  });

  const resetWarningState = () => {
    setHasPendingChanges(false);
    setShowChangesWarning(false);
    setHasSeenUnsavedChangesWarning(false);
  }

  const getCellStyle = (risk: IncidentLikelihoodSurveyResponse) => {
    if (risk.sub_risk_full_name === "") return { display: "none" };
    const firstLetter = getFirstSubRiskId(parseInt(risk.sub_risk_full_name?.[0]))

    if (risk.sub_risk_full_name[1]?.toLocaleLowerCase() === firstLetter) {
      return { display: "inline" };
    } else {
      return { display: "none" };
    }
  };

  return (
    <Modal
      size="max"
      onDismiss={() => {
        hideModals();
        setShowSuccessMessage(false);
      }}
      visible={isShowing("riskRegisterModal")}
      footer={
        <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
          <div>
            <Box>
              {showSuccessMessage && (
                <Flashbar
                  items={[
                    {
                      content: "Threat Register updated",
                      dismissible: true,
                      onDismiss: (_evt) => setShowSuccessMessage(false),
                      id: "message_1",
                      type: "success",
                    },
                  ]}
                />
              )}
            </Box>
            <CancelWarning
              showWarning={showChangesWarning}
              warningText='You have made changes that aren&apos;t saved. Click "save" to keep your changes or "cancel" to discard them.'
              onDismiss={() => setShowChangesWarning(false)}
            />
          </div>

          <Box>
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                disabled={!hasPendingChanges}
                variant="link"
                onClick={(_event) => {
                  if (hasPendingChanges && !hasSeenUnsavedChangesWarning) {
                    setShowChangesWarning(hasPendingChanges);
                    setShowSuccessMessage(false);
                    setHasSeenUnsavedChangesWarning(true);
                    return false;
                  }
                  setShowChangesWarning(false);
                  setHasSeenUnsavedChangesWarning(false);
                  hideModals();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={showLessons || !hasPendingChanges}
                onClick={async (_event) => {
                  const success = await postSurveyAnswers();
                  setShowSuccessMessage(success);
                  resetWarningState();
                  setHasSeenUnsavedChangesWarning(false);
                }}
                variant="primary"
              >
                Submit
              </Button>
            </SpaceBetween>
          </Box>
        </Grid>
      }
    >
      <div>
        <Table
          {...collectionProps}
          onSelectionChange={({ detail }) => {
            setSelectedItems(detail.selectedItems);
          }}
          ariaLabels={{
            selectionGroupLabel: "Items selection",
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${
                selectedItems.length === 1 ? "item" : "items"
              } selected`,
          }}
          columnDefinitions={[
            {
              id: "risk-category",
              header: "Threat category",
              cell: (e: IncidentLikelihoodSurveyResponse) => {
                return (
                  <span style={getCellStyle(e)}>
                    {getFullCategoryName(e.sub_risk_full_name)}
                  </span>
                );
              },
              width: 110,
              minWidth: 109,
            },
            {
              id: "sub_risk_full_name",
              header: "Threat",
              cell: (e: IncidentLikelihoodSurveyResponse) =>
                e.sub_risk_full_name,
              width: 400,
              minWidth: 300,
            },
            {
              id: "risk_applied",
              header: "Threat applied",
              cell: (e: IncidentLikelihoodSurveyResponse) => (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {!showLessons && (
                    <Toggle
                      onChange={({ detail }) => {
                        setSelectedItems([e]);
                        setHasPendingChanges(true);
                        setShowLessons(!detail.checked);
                        //We are no longer editing risk likelihood here, just riskApplied, and if riskApplies goes from 0 to 1, we set the notes and lessons learned to empty 
                    dispatch({
                      Type: "UPDATE_RISK_APPLIES",
                      Cargo: {
                        subRiskId: e.sub_risk_id,
                        riskApplied: detail.checked ? 1 : 0,
                      },
                    });
                        if (detail.checked) {
                          dispatch({
                            Type: "UPDATE_NOTES_AND_LESSONS_LEARNED",
                            Cargo: {
                              subRiskId: e.sub_risk_id,
                              lessonsLearned: "",
                          notes: ""
                            },
                          });
                        }
                      }}
                      checked={e.risk_applied === 1}
                    >
                      <span style={{ marginRight: "10px", width: "100px" }}>
                        {e.risk_applied ? "Applies" : "N/A"}
                      </span>
                    </Toggle>
                  )}
                  {!e.risk_applied && (
                    <Popover
                      dismissButton={true}
                      position="top"
                      size="large"
                      triggerType="custom"
                      content={
                        <LessonsLearned
                          notes={e.notes}
                          lessonsLearned={e.lessons_learned}
                          onClose={(_notes, _lessonsLearned) => {
                            setShowLessons(false);
                          }}
                          showButtons={false}
                        />
                      }
                    >
                      <Icon name="status-info" />
                    </Popover>
                  )}{" "}
                </div>
              ),
              width: 150,
              minWidth: 75,
            },
            {
              id: "location_count",
              header: "On-site incident count (max two years)",
              cell: (e: IncidentLikelihoodSurveyResponse) => {
                if (
                  showLessons &&
                  selectedItems[0].sub_risk_id === e.sub_risk_id
                ) {
                  return (
                    <LessonsLearned
                      notes={e.notes}
                      lessonsLearned={e.lessons_learned}
                      onClose={(notes, lessonsLearned) => {
                        setShowLessons(false);
                        //A cheap way to make either notes or lessons learned a required field for purposes of toggling off a risk
                        if (
                          notes?.length < NOTES_MINIMUM_LENGTH &&
                          lessonsLearned?.length < NOTES_MINIMUM_LENGTH
                        ) {
                          dispatch({
                            Type: "UPDATE_RISK_APPLIES",
                            Cargo: {
                              subRiskId: e.sub_risk_id,
                              riskApplied: 1,
                            },
                          });
                        } else {
                          dispatch({
                            Type: "UPDATE_NOTES_AND_LESSONS_LEARNED",
                            Cargo: {
                              subRiskId: e.sub_risk_id,
                              lessonsLearned: lessonsLearned,
                              notes: notes,
                            },
                          });

                          dispatch({
                            Type: "UPDATE_RISK_APPLIES",
                            Cargo: {
                              subRiskId: e.sub_risk_id,
                              riskApplied: 0,
                            },
                          });
                        }
                      }}
                    />
                  );
                }
                return e.location_count;
              },
              width: 220,
              minWidth: 200,
            },
            {
              id: "region_count",
              header: "Regional",
              cell: (e: IncidentLikelihoodSurveyResponse) =>
                showLessons ? "" : e.region_count,
              width: 100,
              minWidth: 50,
            },
            {
              id: "global_count",
              header: "Global",
              cell: (e: IncidentLikelihoodSurveyResponse) =>
                showLessons ? "" : e.global_count,
              width: 80,
              minWidth: 50,
            },
            {
              id: "assessed_likelihood",
              header: (
                <Popover
                  fixedWidth
                  header="Assessed likelihood descriptions"
                  size="large"
                  content={
                    <ColumnLayout>
                      <Container>
                        <Box variant="awsui-key-label">Rating</Box>
                        <Box variant="awsui-key-label">Definition</Box>
                      </Container>
                      <Container>
                        <Box>
                          1 - <b>Rare</b>
                        </Box>
                        <Box>
                          Could occur at least once every two to three years.
                        </Box>
                      </Container>
                      <Container>
                        <Box>
                          2 - <b>Unlikely</b>
                        </Box>
                        <Box>Could occur at least once per year.</Box>
                      </Container>
                      <Container>
                        <Box>
                          3 - <b>Possible</b>
                        </Box>
                        <Box>Could occur at least once per quarter.</Box>
                      </Container>
                      <Container>
                        <Box>
                          4 - <b>Likely</b>
                        </Box>
                        <Box>Could occur at least once per month.</Box>
                      </Container>
                      <Container>
                        <Box>
                          5 - <b>Almost Certain</b>
                        </Box>
                        <Box>Could occur at least once or more a week.</Box>
                      </Container>
                    </ColumnLayout>
                  }
                >
                  <strong>Assessed likelihood</strong>
                </Popover>
              ),
              cell: (e: IncidentLikelihoodSurveyResponse) => {
                let assessedVal = state.assessedLikelihoodsByRisk.filter(
                  (alr) => alr.sub_risk_id === e.sub_risk_id
                )[0].rating;

                return showLessons ? (
                  ""
                ) : (
                  <SelectLikelihood
                    disabled={e.risk_applied === 0}
                    value={assessedVal}
                    onChange={(newVal) => {
                      setHasPendingChanges(true);
                      dispatch({
                        Type: "UPDATE_ASSESSED_LIKELIHOOD",
                        Cargo: {
                          sub_risk_id: e.sub_risk_id,
                          rating: parseInt(newVal),
                        },
                      });
                      setHasPendingChanges(true);
                    }}
                  />
                );
              },
              width: 180,
              minWidth: 130,
            },
          ]}
          columnDisplay={[
            { id: "risk-category", visible: true },
            { id: "sub_risk_full_name", visible: true },
            { id: "risk_applied", visible: true },
            { id: "location_count", visible: true },
            { id: "region_count", visible: false },
            { id: "global_count", visible: false },
            { id: "assessed_likelihood", visible: true },
          ]}
          enableKeyboardNavigation
          items={itemsFromUseCollection}
          loadingText="Loading likelihood data"
          resizableColumns
          wrapLines
          trackBy="sub_risk_full_name"
          filter={
            <TextFilter
              {...filterProps}
              countText={getMatchesCountText(filteredItemsCount)}
              filteringPlaceholder="Find a threat"
              filteringAriaLabel="Filter threats"
            />
          }
          header={
            <Header>
              {selectedEntity.site} threat register + incident likelihood
              information
            </Header>
          }
        />
      </div>
    </Modal>
  );

};

export default RiskRegisterModal;
