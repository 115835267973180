const StatusLight = ({ color }: { color: string }) => (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <circle 
        cx="12" 
        cy="12" 
        r="10"
        fill={color}
        stroke="#888"
        strokeWidth="1"
      />
    </svg>
  );

  export default StatusLight;