import React, { useContext } from "react";
import { RoleContext } from "./RoleProvider";
import light from "src/images/SEAV-icon_ico.svg";
import { TopNavigation } from "@amzn/awsui-components-react";
import AppLayout from "./AppLayout";

const LayoutWrapper = () => {
  const { loggedInUser } = useContext(RoleContext);

  return (
    <div>
      <TopNavigation
        identity={{
          href: "/",
          title: "Ballast",
          logo: {
            src: light,
            alt: "Ballast Logo",
          },
        }}
        utilities={[
          {
            type: "menu-dropdown",
            text: "SecEvals Wikis",
            iconName: "external",
            items: [
              {
                id: "ballast",
                text: "Ballast Wiki",
                href: "https://w.amazon.com/bin/view/AmazonCorporateSecurity/GlobalProgramsandServices/SecurityEvaluations/Ballast/",
                external: true,
                externalIconAriaLabel: " (opens new tab to Wiki page)",
              },
              {
                id: "primarywiki",
                text: "SecEvals Wiki",
                href: "https://w.amazon.com/bin/view/AmazonCorporateSecurity/GlobalProgramsandServices/SecurityEvaluations/",
                external: true,
                externalIconAriaLabel: " (opens new tab to Wiki page)",
              },
              {
                id: "terminologywiki",
                text: "SecEvals Terminology Wiki",
                href: "https://w.amazon.com/bin/view/AmazonCorporateSecurity/GlobalProgramsandServices/SecurityEvaluations/SecEvalTerminology/",
                external: true,
                externalIconAriaLabel: " (opens new tab to Wiki page)",
              },
            ],
          },
          {
            type: "button",
            iconName: "notification",
            title: "Notifications",
            ariaLabel: "Notifications",
            badge: false,
            disableUtilityCollapse: false,
          },
          {
            type: "menu-dropdown",
            text: loggedInUser,
            description: loggedInUser + "@amazon.com",
            iconName: "user-profile",
            items: [],
          },
        ]}
      />
      <div>
        <AppLayout />
      </div>
    </div>
  );
};

export default LayoutWrapper;
