import { Dispatch, createContext, useContext, useEffect, useReducer } from "react";
import { IncidentLikelihoodSurveyResponse, ReducerAction } from "../types";
import { RoleContext } from "./RoleProvider";
import { getBaseUrl } from "src/utils";
import { SelectedSiteContext } from "./useSelectedSite";
import { SiteLogContext } from "./HomePage/useSiteLog";
import useSecuredFetch from "./useSecuredFetch";

export type LikelihoodSurveyStateType = {
    assessedLikelihoodsByRisk: IncidentLikelihoodSurveyResponse[];
    successfullyUpdated: boolean;
}

export type RiskConsequenceContextType = {
    state: LikelihoodSurveyStateType;
    dispatch: Dispatch<ReducerAction>;
    postSurveyAnswers: () => Promise<boolean>;
};

function reducer(state: LikelihoodSurveyStateType, action: ReducerAction): LikelihoodSurveyStateType {
    let copyOfAssessedLikelihoods: IncidentLikelihoodSurveyResponse[];
    let responseToUpdate: IncidentLikelihoodSurveyResponse;

    switch (action.Type) {
        case "HYDRATE_COUNTS":
            const emptySurveyQuestions: IncidentLikelihoodSurveyResponse[] = action.Cargo.incidentCounts.map((row: IncidentLikelihoodSurveyResponse) => ({
                entity_id: row.entity_id,
                entity_name: row.name,
                sub_risk_id: row.sub_risk_id,
                sub_risk_full_name: row.sub_risk_full_name,
                rating: row.rating,
                location_count: row.location_count,
                region_count: row.region_count,
                global_count: row.global_count,
                risk_applied: row.risk_applied,
                lessons_learned: row.lessons_learned,
                notes: row.notes
            }));

            return {
                ...state,
                assessedLikelihoodsByRisk: emptySurveyQuestions
            };
        case "UPDATE_ASSESSED_LIKELIHOOD":
            copyOfAssessedLikelihoods = [...state.assessedLikelihoodsByRisk];
            responseToUpdate = copyOfAssessedLikelihoods.filter((alr) => alr.sub_risk_id === action.Cargo.sub_risk_id)[0];
            responseToUpdate.rating = action.Cargo.rating;
            return {
                ...state,
                assessedLikelihoodsByRisk: copyOfAssessedLikelihoods,
                successfullyUpdated: false
            };

        case "UPDATE_RISK_APPLIES":
            copyOfAssessedLikelihoods = [...state.assessedLikelihoodsByRisk];
            responseToUpdate = copyOfAssessedLikelihoods.filter((alr) => alr.sub_risk_id === action.Cargo.subRiskId)[0];
            responseToUpdate.risk_applied = action.Cargo.riskApplied;
            return {
                ...state,
                assessedLikelihoodsByRisk: copyOfAssessedLikelihoods,
                successfullyUpdated: false
            };

        case "UPDATE_NOTES_AND_LESSONS_LEARNED":
            copyOfAssessedLikelihoods = [...state.assessedLikelihoodsByRisk];
            responseToUpdate = copyOfAssessedLikelihoods.filter((alr) => alr.sub_risk_id === action.Cargo.subRiskId)[0];
            responseToUpdate.notes = action.Cargo.notes;
            responseToUpdate.lessons_learned = action.Cargo.lessonsLearned;
            return {
                ...state,
                assessedLikelihoodsByRisk: copyOfAssessedLikelihoods,
                successfullyUpdated: false
            };

        case "UPDATE_SAVE_SUCCESS":
            return {
                ...state,
                successfullyUpdated: action.Cargo
            };


    }
    return state;
}


const initialState = {
    assessedLikelihoodsByRisk: [{
        entity_id: "0",
        name: "",
        rating: 0,
        risk_category_name: "",
        risk_applied: 0,
        sub_risk_id: 0,
        sub_risk_full_name: "",
        location_count: 0,
        region_count: 0,
        global_count: 0,
        notes: "",
        lessons_learned: ""
    }],
    updateRiskApplied: () => { },
    successfullyUpdated: false
};

export const LikelihoodSurveyContext = createContext<RiskConsequenceContextType>({
    state: initialState,
    dispatch: () => null,
    postSurveyAnswers: () => {
        return new Promise((resolve) => {
            resolve(false);
        });
    }
});

export const LikelihoodSurveyProvider = (props: { children: JSX.Element }) => {
    const { selectedEntity, refreshHealthCheck } = useContext(SelectedSiteContext);
    const { loggedInUser, token } = useContext(RoleContext);
    const secureFetch = useSecuredFetch();

    useEffect(() => {
        const fxn = async () => {
            if (selectedEntity.site !== "") {
                const res = await secureFetch(`${getBaseUrl()}/riskLikelihood?entityName=${selectedEntity.site}`, 'GET');
                if (res.status !== 200) {

                    return;
                }
                const json = await res.json();
                const correctedRows = json.data?.map((like: { suggested_rating: number }) => ({ ...like, assessed_likelihood: like.suggested_rating }));
                dispatch({ Type: "HYDRATE_COUNTS", Cargo: { incidentCounts: correctedRows } });
            }
        }
        fxn();
    }, [selectedEntity.site]);

    const [state, dispatch] = useReducer(reducer, initialState);
    const { refetchLogForSelectedSite } = useContext(SiteLogContext);
    const postSurveyAnswers = async () => {
        const res = await secureFetch(`${getBaseUrl()}/riskLikelihoodForSite`, "PUT", JSON.stringify({
            user: loggedInUser,
            entityId: selectedEntity.entityId,
            entityName: selectedEntity.site,
            surveyResponses: state.assessedLikelihoodsByRisk

        }));


        dispatch({ Type: "UPDATE_SAVE_SUCCESS", Cargo: res.status === 200 });
        if (res.ok) {
            refreshHealthCheck();
            refetchLogForSelectedSite();
        }
        return res.ok;
    }





    return <LikelihoodSurveyContext.Provider value={{ state, dispatch, postSurveyAnswers }}>{props.children}</LikelihoodSurveyContext.Provider>
}