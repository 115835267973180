import {BETA_API_BASE_URL, PROD_API_BASE_URL} from "./constants"

//This is the official date format: 20-03-2025, but we didn't decide on that til pretty close to the ship date.

export const prettyDate = (date: string) => {
    let retVal = "";
    if (date.indexOf("T") > -1) {
        retVal = date.split("T").join(" ");
    } else {
        retVal = date;
    }
    const lastColonIndex = retVal.lastIndexOf(":");
    retVal = retVal.substring(0, lastColonIndex);
    return retVal;
}
//Someday we should update all uses of these pretty**** functions to use the new format.
export const prettyDateString = (date: string) => {

    if(date?.includes("T")) {
        return date.split("T")[0];
    } else if (date?.includes(" ")) {
        return date.split(" ")[0];
    }
    return "";
}

export const prettyDateTime = (date: string) => {
    let retVal = "";
    if (date.indexOf("T") > -1) {
        retVal = date.split("T").join(" ");
    }
    const lastColonIndex = retVal.lastIndexOf(":");
    retVal = retVal.substring(0, lastColonIndex);
    return retVal;
}

export const reformatDate = (date: string) => {
    const parts = date.split("-");
    return parts.length === 3 ? `${parts[2]}-${parts[1]}-${parts[0]}` : date;
  }

export const formatBallastDate = (date: string) => {
    let datePart = date;
    if (date.indexOf("T") > -1) {
        datePart = date.split("T")[0];
    }
  
    let parts = datePart.split("-");
    return parts.length === 3 ? `${parts[2]}-${parts[1]}-${parts[0]}` : datePart;
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

export const getMonthAndYear = (d : Date) => {
      return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
} 

export const getMonthAndDay = (d : string) => {
    if(d === "")  return "";
    const asDate = new Date(d);
    if(!Number.isNaN(asDate)) {
        return `${monthNames[asDate.getMonth()]} ${asDate.getDate()}`;
    }
     return "";
} 

export const getMonthDayAndYear = (d : string) => {
    if(d === "")  return "";
    const asDate = new Date(d);
    if(!Number.isNaN(asDate)) {
        return `${monthNames[asDate.getMonth()]} ${asDate.getDate()}, ${asDate.getFullYear()}`;
    }
     return "";
} 

export function getMatchesCountText(count: number | undefined) {
    return count === 1 ? `1 match` : `${count} matches`;
  }
  
export const getBaseUrl = () => {
    const currentUrl = window.location.origin;
    if(currentUrl.indexOf("https://risk.acs.amazon.dev") === 0){
       return PROD_API_BASE_URL;
    }
    return BETA_API_BASE_URL;
}