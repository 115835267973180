import React, { useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Container } from "@amzn/awsui-components-react";
import { ModalContext } from "../useModal";

const ReadOnlyControlsTableInstructionsModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);
  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("readOnlyControlsTableSCMP")}
    >
      <Container>
        <h3 className="center">Security Controls Management Plan (SCMP)</h3>
        <div className="modal-body">

          The SCMP provides basic information about security controls, how they are used, and how they contribute to the protection strategy (security depth). Included is information about common security threat management frameworks and concepts to aid in the design and implementation of the protection plan.
          <p>Support document coming soon</p>
        </div>
      </Container>
    </Modal >
  );
};

export default ReadOnlyControlsTableInstructionsModal;
