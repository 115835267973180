import { createContext, useContext, useEffect, useState } from "react";
import { SelectedSiteContext } from "../useSelectedSite";
import { ControlDataContext } from "../ControlsProvider";
import { ControlsSurveyContext } from "../ControlsSurveyProvider";
import { RiskContext } from "../RiskProvider";
import { ControlSetContext } from "../ControlSetProvider";
import { DefenseInDepthControlType, ControlType, RiskControlCriticalityDBType } from "src/types";
import { IsUtilizedValues } from "../../constants"

export type ReadOnlyControlsSetContextType = {
    controlsInUseAtCurrentSite: DefenseInDepthControlType[],
    controlsNotUsedAtCurrentSite: DefenseInDepthControlType[],
    nDelayControlsInUse: number,
    nDeterControlsInUse: number,
    nDetectControlsInUse: number,
    nRecoverControlsInUse: number,
    nRespondControlsInUse: number,
    nDelayControlsNotUsed: number,
    nDeterControlsNotUsed: number,
    nDetectControlsNotUsed: number,
    nRecoverControlsNotUsed: number,
    nRespondControlsNotUsed: number,
    selectedRiskName: string
}

export const SiteControlsContext = createContext<ReadOnlyControlsSetContextType>({
    controlsInUseAtCurrentSite: [],
    controlsNotUsedAtCurrentSite: [],
    nDelayControlsInUse: 0,
    nDeterControlsInUse: 0,
    nDetectControlsInUse: 0,
    nRecoverControlsInUse: 0,
    nRespondControlsInUse: 0,
    nDelayControlsNotUsed: 0,
    nDeterControlsNotUsed: 0,
    nDetectControlsNotUsed: 0,
    nRecoverControlsNotUsed: 0,
    nRespondControlsNotUsed: 0,
    selectedRiskName: ""
});

export const SiteControlsProvider = (props: { children: JSX.Element }) => {
    const { site } = useContext(SelectedSiteContext);
    const { allControls } = useContext(ControlDataContext);
    const [controlsInUseAtCurrentSite, setControlsInUseAtCurrentSite] = useState<DefenseInDepthControlType[]>([]);
    const [controlsNotUsedAtCurrentSite, setControlsNotUsedAtCurrentSite] = useState<DefenseInDepthControlType[]>([]);

    const { state: controlSurveyState } = useContext(ControlsSurveyContext);
    const { state: riskState, dispatch: riskDispatch } = useContext(RiskContext);
    const { surveyQuestions } = controlSurveyState;
    const { state: controlSetState } = useContext(ControlSetContext);
    const [selectedRiskName, setSelectedRiskName] = useState("")
    const [nDeterControlsInUse, setnDeterControlsInUse] = useState(0);
    const [nDetectControlsInUse, setnDetectControlsInUse] = useState(0);
    const [nDelayControlsInUse, setnDelayControlsInUse] = useState(0);
    const [nRespondControlsInUse, setnRespondControlsInUse] = useState(0);
    const [nRecoverControlsInUse, setnRecoverControlsInUse] = useState(0);
    const [nDeterControlsNotUsed, setnDeterControlsNotUsed] = useState(0);
    const [nDetectControlsNotUsed, setnDetectControlsNotUsed] = useState(0);
    const [nDelayControlsNotUsed, setnDelayControlsNotUsed] = useState(0);
    const [nRespondControlsNotUsed, setnRespondControlsNotUsed] = useState(0);
    const [nRecoverControlsNotUsed, setnRecoverControlsNotUsed] = useState(0);

    useEffect(() => {
        setControlsInUseAtCurrentSite([]);
        if (site !== ""
            && controlSurveyState.surveyQuestions.length > 0
            && riskState.selectedSubRisk.acs_risk_dim_sk !== 0
            && !controlSetState.isFetching) {
            const { controlCriticalitiesByRisk: globalControlCriticalitiesByRisk } = controlSetState;
            const theRisk = riskState.selectedSubRisk.acs_risk_dim_sk;
            setSelectedRiskName(riskState.selectedSubRisk.sub_risk_name);
            const theControlsInUse: DefenseInDepthControlType[] = [];
            const theControlsNotUsed: DefenseInDepthControlType[] = [];

            for (let i = 0; i < surveyQuestions.length; i++) {
                const thisQuestion = surveyQuestions[i];
                //we need to get the control corresponding to this survey question so we can get the delays/detects, etc.
                const thisControl = allControls?.find((a: ControlType) => a.id === thisQuestion.control_id);
                if (thisControl) {
                    const globalControlSetForThisRisk = globalControlCriticalitiesByRisk[theRisk];
                    if (globalControlSetForThisRisk.some((a: RiskControlCriticalityDBType) => a.control_id == thisControl.id.toString())) {
                        if( thisQuestion.is_utilized !== IsUtilizedValues.N_A) {
                            theControlsInUse.push({
                                controlName: thisQuestion.control_name,
                                criticality: globalControlSetForThisRisk
                                    .find((a: RiskControlCriticalityDBType) => (a.control_id == thisControl.id.toString()))?.criticality || 0,
                                delay: thisControl!.delay,
                                deter: thisControl!.deter,
                                respond: thisControl!.respond,
                                detect: thisControl!.detect,
                                recover: thisControl!.recover,
                                isUsedAtSite: (surveyQuestions[i].is_utilized == IsUtilizedValues.PARTIAL || surveyQuestions[i].is_utilized == IsUtilizedValues.YES)
                            });
                        } else {
                            theControlsNotUsed.push({
                                controlName: thisQuestion.control_name,
                                criticality: globalControlSetForThisRisk
                                    .find((a: RiskControlCriticalityDBType) => (a.control_id == thisControl.id.toString()))?.criticality || 0,
                                delay: thisControl!.delay,
                                deter: thisControl!.deter,
                                respond: thisControl!.respond,
                                detect: thisControl!.detect,
                                recover: thisControl!.recover,
                                isUsedAtSite: (surveyQuestions[i].is_utilized == IsUtilizedValues.PARTIAL || surveyQuestions[i].is_utilized == IsUtilizedValues.YES)
                            });
                        }
                    }
                    
                }
            }
            setControlsInUseAtCurrentSite(theControlsInUse.sort((a: DefenseInDepthControlType, b: DefenseInDepthControlType) => {
                if (a.isUsedAtSite && !b.isUsedAtSite) {
                    return -1;
                } else if (!a.isUsedAtSite && b.isUsedAtSite) {
                    return 1;
                } else {
                    return a.controlName.localeCompare(b.controlName);
                }
            }));

            setControlsNotUsedAtCurrentSite(theControlsNotUsed.sort((a: DefenseInDepthControlType, b: DefenseInDepthControlType) => {
                if (a.isUsedAtSite && !b.isUsedAtSite) {
                    return -1;
                } else if (!a.isUsedAtSite && b.isUsedAtSite) {
                    return 1;
                } else {
                    return a.controlName.localeCompare(b.controlName);
                }
            }));

            const depthOfDefenseControlsInUseAtThisSite = theControlsInUse.filter((ctrl: DefenseInDepthControlType) => (ctrl.isUsedAtSite))
            setnDelayControlsInUse(depthOfDefenseControlsInUseAtThisSite.filter((a: DefenseInDepthControlType) => a.delay === 1).length);
            setnDeterControlsInUse(depthOfDefenseControlsInUseAtThisSite.filter((a: DefenseInDepthControlType) => a.deter === 1).length);
            setnDetectControlsInUse(depthOfDefenseControlsInUseAtThisSite.filter((a: DefenseInDepthControlType) => a.detect === 1).length);
            setnRecoverControlsInUse(depthOfDefenseControlsInUseAtThisSite.filter((a: DefenseInDepthControlType) => a.recover === 1).length);
            setnRespondControlsInUse(depthOfDefenseControlsInUseAtThisSite.filter((a: DefenseInDepthControlType) => a.respond === 1).length);
            
            setnDelayControlsNotUsed(theControlsNotUsed.filter((a: DefenseInDepthControlType) => a.delay === 1).length);
            setnDeterControlsNotUsed(theControlsNotUsed.filter((a: DefenseInDepthControlType) => a.deter === 1).length);
            setnDetectControlsNotUsed(theControlsNotUsed.filter((a: DefenseInDepthControlType) => a.detect === 1).length);
            setnRecoverControlsNotUsed(theControlsNotUsed.filter((a: DefenseInDepthControlType) => a.recover === 1).length);
            setnRespondControlsNotUsed(theControlsNotUsed.filter((a: DefenseInDepthControlType) => a.respond === 1).length);
        }
    }, [site, controlSurveyState.surveyQuestions.length, riskState.selectedSubRisk, controlSetState.isFetching]);

    useEffect(() => {
        riskDispatch({ Type: "SET_SELECTED_SUBRISK_TO_EMPTY", Cargo: undefined });
    }, [site]);

    return (<SiteControlsContext.Provider value={{
        controlsInUseAtCurrentSite,
        controlsNotUsedAtCurrentSite,
        nDelayControlsInUse,
        nDeterControlsInUse,
        nDetectControlsInUse,
        nRecoverControlsInUse,
        nRespondControlsInUse,
        nDelayControlsNotUsed,
        nDeterControlsNotUsed,
        nDetectControlsNotUsed,
        nRecoverControlsNotUsed,
        nRespondControlsNotUsed,
        selectedRiskName
    }}>
        {props.children}
    </SiteControlsContext.Provider>)
}