import React, { useEffect, useState, createContext } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoUserSession, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { getBaseUrl } from "../utils";
import { DEFAULT_LOCALE } from 'src/constants';


export type RoleContextType = {
    authenticated: boolean;
    authorizedToViewApp: boolean;
    loggedInUser: string;
    fakeLoggedInUser: string;
    ensureSession: () => Promise<boolean>;
    errorMessage: string;
    setLoggedInUser: (alias: string) => void;
    token: string;
    mostPowerfulRole: string;
    roleFetchComplete: boolean;
    userIsRiskAdmin: boolean; //Super user
    userIsRiskManager: boolean; //ASMs and RSMs
    userIsViewOnly: boolean;
}

const RoleContext = createContext<RoleContextType>({
    authenticated: false,
    authorizedToViewApp: false,
    ensureSession: () => (Promise.resolve(false)),
    loggedInUser: "",
    errorMessage: "",
    fakeLoggedInUser: "",
    userIsRiskAdmin: false, //Super user
    userIsRiskManager: false, // RSMs and ASMs
    setLoggedInUser: (_alias: string) => undefined,
    token: "",
    mostPowerfulRole: "",
    roleFetchComplete: false,
    userIsViewOnly: false
})


const RoleContextProvider = (props: { children: JSX.Element }) => {
    const [loggedInUser, setLoggedInUser] = useState("");
    
    const qs = window.location.search;
    let qsMgr: string | null = null;

    const keyValPairs = qs?.slice(1).split("&");
    for (let i = 0; i < keyValPairs.length; i++) {
        const thisKey = keyValPairs[i].split("=")[0];
        if (thisKey === "mgr") {
            qsMgr = keyValPairs[i].split("=")[1];
            break;
        }
    }


    const [fakeLoggedInUser, setFakeLoggedInUser] = useState<string>(qsMgr === null ? "" : qsMgr);
    const [alias, setAlias] = React.useState(" _ ")
    const [roleFetchComplete, setRoleFetchComplete] = React.useState(false)
    const [authenticated, setAuthenticated] = React.useState(false);
    const [token, setToken] = useState("");
    const [userIsRiskManager, setUserIsRiskManager] = useState(false);
    const [userIsRiskAdmin, setUserIsRiskAdmin] = useState(false);
    const [userIsViewOnly, setUserIsViewOnly] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [authorizedToViewApp, setAuthorizedToViewApp] = useState(false);

    const ensureSession = async () => {
        let cognitoUserData;
        let theToken;
        try {
            cognitoUserData = await Auth.currentAuthenticatedUser();
            const rawAlias = await cognitoUserData.getUsername();
            setAuthenticated(true);
            setAlias(rawAlias);
            const currentSession = await Auth.currentSession();

            theToken = currentSession.getIdToken().getJwtToken();
            setToken(theToken);
            const actualAlias = rawAlias.split('_')[1];
            setLoggedInUser(actualAlias);
            console.log("here in ensureSession, currentAuthenticatedUser is good");
            return true;

        } catch (err: any) {
            console.log("here in ensureSession, currentAuthenticatedUser threw and we are about to sign in again");
            await Auth.federatedSignIn({
                customProvider: 'AmazonFederate'
            }).catch((err: any) => {
                console.log('Error trying to sign user in via Federate..', err);
            });

        };
        return false;
    }

    useEffect(() => {
        ensureSession();
    }, []);

    const roleFetcher = async () => {
        const roleCheckURL = `${getBaseUrl()}/roleCheck`;
        await ensureSession();
        const secureRoleRes = await fetch(roleCheckURL, {
            method: 'POST',
            headers: new Headers({
                "wowie": token,
                "Content-Type": 'text/plain',
                "Access-Control-Allow-Origin": "*"
            }),
            body: JSON.stringify({ alias },
            )
        })
        if (secureRoleRes.status !== 200) {
            setErrorMessage("Unable to verify security roles");
            return;
        }
        const secureRoleJson = await secureRoleRes.json();
        setMostPowerfulRole(secureRoleJson.role);
        switch (secureRoleJson.role) {
            case "admin":
                setUserIsRiskAdmin(true);
                setUserIsRiskManager(false);
                setUserIsViewOnly(false);
                break;

            case "manager":
                setUserIsRiskAdmin(false);
                setUserIsRiskManager(true);
                setUserIsViewOnly(false);
                break;

            case "viewer":
                setUserIsRiskAdmin(false);
                setUserIsRiskManager(false);
                setUserIsViewOnly(true);
                break;

            default:
                setErrorMessage("You are not in any of the Ballast security groups");
                break;
        }
        setAuthorizedToViewApp(userIsRiskAdmin || userIsRiskManager || userIsViewOnly);
        setRoleFetchComplete(true)
    }

    useEffect(() => {
        if (alias.length > 0 && token.length > 0) {
            roleFetcher();
        }
    }, [alias, token]);



    const [mostPowerfulRole, setMostPowerfulRole] = useState("unauthorized");
    //let admins simulate being a manager using the query string
    useEffect(() => {
        if (userIsRiskAdmin) {
            const qs = window.location.search;
            let qsMgr: string | null = null;

            const keyValPairs = qs?.slice(1).split("&");
            for (let i = 0; i < keyValPairs.length; i++) {
                const thisKey = keyValPairs[i].split("=")[0];
                if (thisKey === "mgr") {
                    qsMgr = keyValPairs[i].split("=")[1];
                    break;
                }
            }

            setFakeLoggedInUser(qsMgr === null ? "" : qsMgr)
        }
    }, [window.location, roleFetchComplete]);

    return <RoleContext.Provider value={{
        authenticated,
        authorizedToViewApp,
        ensureSession,
        errorMessage,
        fakeLoggedInUser,
        loggedInUser,
        mostPowerfulRole,
        roleFetchComplete,
        setLoggedInUser,
        token,
        userIsRiskAdmin,
        userIsRiskManager,
        userIsViewOnly,
    }}>{props.children}</RoleContext.Provider>;
}

export { RoleContext, RoleContextProvider }