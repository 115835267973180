import { createContext, Dispatch, useContext, useEffect, useReducer, useState } from "react";
import { ReducerAction, SiteLogEntryDBType, SiteNoteDBType } from "src/types";
import useSecuredFetch from "../useSecuredFetch";
import { SESSION_TIMEOUT_ERROR } from "../../constants"
import { getBaseUrl, formatBallastDate } from "src/utils";
import { SelectedSiteContext } from "../useSelectedSite";
import { ControlDataContext } from "../ControlsProvider";
import { RoleContext } from "../RoleProvider";


/*
This holds a list of log entries for the currently active site
*/

export type SiteLogStateType = {
    logEntries: SiteLogEntryDBType[];
    notes: SiteNoteDBType[];
    error: string | null;
    isFetchingLogs: boolean;
    isFetchingNotes: boolean;
    successMessage: string | null;
}

function Reducer(state: SiteLogStateType, action: ReducerAction) {
    switch (action.Type) {
        case "UPDATE_LOG_ENTRIES": {

            return {
                ...state,
                logEntries: action.Cargo,
                error: null,
                isFetchingLogs: false
            }
        }

        case "SET_IS_FETCHING_TRUE": {
            return {
                ...state,
                isFetchingLogs: true
            }
        }

        case "FETCH_LOG_401": {
            return {
                ...state,
                error: action.Cargo,
                successMessage: null,
                isFetchingNotes: false
            }
        }

        case "SET_IS_FETCHING_NOTES_TRUE": {
            return {
                ...state,
                isFetchingNotes: true
            }
        }

        case "UPDATE_NOTES": {
            return {
                ...state,
                notes: action.Cargo,
                error: null,
                isFetchingNotes: false
            }
        }
    }

    return state;
}


export const SiteLogContext = createContext<{
    state: SiteLogStateType,
    dispatch: Dispatch<ReducerAction>,
    refetchLogForSelectedSite: () => void,
    postNewNote: (note: string) => void
}>(
    {
        state: {
            logEntries: [],
            notes: [],
            error: "",
            isFetchingLogs: true,
            isFetchingNotes: true,
            successMessage: null
        },
        dispatch: () => null,
        refetchLogForSelectedSite: () => null,
        postNewNote: (_note: string) => null,
    });

export const SiteLogProvider = (props: { children: JSX.Element }) => {
    const { children } = props;
    const secureFetch = useSecuredFetch();
    const [state, dispatch] = useReducer(Reducer, {
        logEntries: [],
        notes: [],
        error: "",
        isFetchingLogs: true,
        isFetchingNotes: true,
        successMessage: ""
    });
    const { loggedInUser } = useContext(RoleContext);
    const [refreshActivityLogCounter, setRefreshActivityLogCounter] = useState(0);
    const [refreshNotesLogCounter, setRefreshNotesLogCounter] = useState(0);
    const { site, selectedEntity } = useContext(SelectedSiteContext);
    const { getControlInfo } = useContext(ControlDataContext);

    const fetchSiteLog = async () => {
        dispatch({ Type: "UPDATE_LOG_ENTRIES", Cargo: [] });
        dispatch({ Type: "SET_IS_FETCHING_TRUE", Cargo: "" });

        if (site !== "") {
            const res = await secureFetch(`${getBaseUrl()}/siteLog?entityId=${selectedEntity.entityId}`, 'GET');
            if (res.status === 401) {
                dispatch({ Type: "FETCH_LOG_401", Cargo: SESSION_TIMEOUT_ERROR });
            }
            const logEntriesToDisplay: SiteLogEntryDBType[] = [];
            const logz = await res.json();
            for (let i = 0; i < logz.logEntries.length; i++) {
                const currentLogEntry = logz.logEntries[i];
                const parts = currentLogEntry.column_that_changed.split(".");
                if (currentLogEntry.table_that_changed == "Controls register") {
                    const controlInfo = getControlInfo(parts[0]);
                    const updated = `${controlInfo.controlName}.${parts[1]}`;
                    logEntriesToDisplay.push({
                        ...currentLogEntry,
                        column_that_changed: updated,
                        changed_on: currentLogEntry.changed_on !== "" ? formatBallastDate(currentLogEntry.changed_on) : ""
                    });
                } else if (currentLogEntry.table_that_changed == "Threat register") {
                    logEntriesToDisplay.push({
                        ...currentLogEntry,
                        changed_on: currentLogEntry.changed_on !== "" ? formatBallastDate(currentLogEntry.changed_on) : ""
                    });
                }
            }
            dispatch({ Type: "UPDATE_LOG_ENTRIES", Cargo: logEntriesToDisplay });
        }
    }


    useEffect(() => {
        fetchSiteLog();
    }, [site, refreshActivityLogCounter]);

    const fetchSiteNotes = async () => {
        dispatch({ Type: "UPDATE_NOTES", Cargo: [] });
        dispatch({ Type: "SET_IS_FETCHING_NOTES_TRUE", Cargo: "" });

        if (site !== "") {
            const res = await secureFetch(`${getBaseUrl()}/getNotesFor?entityId=${selectedEntity.entityId}`, 'GET');
            if (res.status === 401) {
                dispatch({ Type: "FETCH_LOG_401", Cargo: SESSION_TIMEOUT_ERROR });
            }
            const notesToDisplay: SiteNoteDBType[] = [];
            const logz = await res.json();
            for(let i = 0; i < logz.notes.length; i++){
                const currentNote = logz.notes[i];
                notesToDisplay.push({
                    ...currentNote,
                    added_on: currentNote.added_on !== "" ? formatBallastDate(currentNote.added_on) : ""
                });
            
            }
            dispatch({ Type: "UPDATE_NOTES", Cargo: notesToDisplay });
        }
    }

    useEffect(() => {
        fetchSiteNotes();
    }, [site, refreshNotesLogCounter]);

    const refetchLogForSelectedSite = () => {
        setRefreshActivityLogCounter((old) => old + 1);
    }

    const postNewNote = async (text: string) => {
        const res = await secureFetch(`${getBaseUrl()}/postNoteFor`, "POST", JSON.stringify({
            user: loggedInUser,
            entityId: selectedEntity.entityId,
            note: text
        }));

        if (res.status === 200) {
            setRefreshNotesLogCounter((old) => old + 1);
            dispatch({ Type: "UPDATE_SAVE_RESULT_MESSAGE", Cargo: "Note successfully submitted" });
        } else {
            dispatch({ Type: "UPDATE_SAVE_RESULT_MESSAGE", Cargo: "Unable to submit your note" })
        }
    }


    return (
        <SiteLogContext.Provider value={{ state, dispatch, refetchLogForSelectedSite, postNewNote }}>
            {children}
        </SiteLogContext.Provider>
    );
};