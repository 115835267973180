import React, { useContext} from "react";
import EntityTable from "./HomePage/EntityTable";
import TriagedIncidentsTable from "./incidents/TriagedIncidentsTable";
import { FlashMessageContext } from "./useFlashMessages";

import {
    ColumnLayout,
    Container,
    Header,
} from "@amzn/awsui-components-react/polaris";

const PrimaryView = () => {
    const { resetMessages } = useContext(FlashMessageContext);

    return (<>
        <Container
            header={
                <ColumnLayout columns={2}>
                    <h2 className="poseiden">ACS global sites</h2>
                </ColumnLayout>
            }
        >
            <EntityTable clearMessages={resetMessages} />
        </Container>
        <Container
            header={<Header variant="h2"><span className="poseiden">Security incident database (workbench)</span></Header>} variant="stacked"
        >
            <TriagedIncidentsTable showEditBtn={false} />
        </Container>
        </>);
};

export default PrimaryView;