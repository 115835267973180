import React from 'react';
import { createRoot } from 'react-dom/client';
import { ModalContextProvider } from "./components/useModal";
import { RoleContextProvider } from "./components/RoleProvider";
import { IncidentsProvider } from "./components/incidents/IncidentsProvider";
import { EntityAndManagerProvider } from "./components/EntityAndManagerProvider";
import { SelectedSiteContextProvider } from "./components/useSelectedSite";
import { RiskContextProvider } from "./components/RiskProvider";
import { ControlSetProvider } from "./components/ControlSetProvider";
import { ControlProvider } from "./components/ControlsProvider";
import { ControlsSurveyContextProvider } from "./components/ControlsSurveyProvider";
import { LikelihoodSurveyProvider } from "./components/LikelihoodSurveyContext";
import { DefinitionsContextProvider } from './components/DefinitionsProvider';
import { FlashMessageProvider } from './components/useFlashMessages'
import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './logger';
import Amplify, { Auth } from 'aws-amplify';
import { getAmplifyConfig } from './amplifyConfig';

import '@amzn/katal-components/styles.css';
import './index.scss';


const callbackURL = window.location.origin;
const configObj = getAmplifyConfig(callbackURL);
if (configObj) {
    configObj.API.graphql_headers = async () => ({
        'Authorization': (await Auth.currentAuthenticatedUser()).getSignInUserSession().getIdToken().getJwtToken(),
        'AuthUser': (await Auth.currentAuthenticatedUser()).getUsername()
    });

    Amplify.configure(configObj);
}


const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <AppInitWrapper>
            <RoleContextProvider>
                <DefinitionsContextProvider>
                    <ModalContextProvider>
                        <SelectedSiteContextProvider>
                            <RiskContextProvider>
                                <ControlProvider>
                                    <ControlSetProvider>
                                        <EntityAndManagerProvider>
                                            <ControlsSurveyContextProvider>
                                                <IncidentsProvider>
                                                    <LikelihoodSurveyProvider>
                                                        <FlashMessageProvider>
                                                            <App />
                                                        </FlashMessageProvider>
                                                    </LikelihoodSurveyProvider>
                                                </IncidentsProvider>
                                            </ControlsSurveyContextProvider>
                                        </EntityAndManagerProvider>
                                    </ControlSetProvider>
                                </ControlProvider>
                            </RiskContextProvider>
                        </SelectedSiteContextProvider>
                    </ModalContextProvider>
                </DefinitionsContextProvider>
            </RoleContextProvider>
        </AppInitWrapper>
    </React.StrictMode>
);
