import { useContext, useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import AddSiteModal from "../AddSiteModal";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { EntityType } from "../../types";
import { paginationLabels, RED, YELLOW, GREEN } from "../../constants";
import { SelectedSiteContext } from "../useSelectedSite";
import { EntityAndManagerContext } from "../EntityAndManagerProvider";
import EmptyState from "../EmptyState";
import { useLocalStorage } from "src/utils/use-local-storage";
import {SiteContext} from "./useNewTabbedView";
import StatusLight from "./StatusLight";
import { Popover } from "@amzn/awsui-components-react";


export type EntityTableProps = {
  clearMessages: () => void;
};

type ContentDisplayItem = {
  id: string;
  visible: boolean;
};

function getMatchesCountText(count: number | undefined) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export default ({ clearMessages }: EntityTableProps) => {
  const { setSelectedLocation } = useContext(SelectedSiteContext);
  const {setInTabbedView} = useContext(SiteContext);
  const { entities, isLoading } = useContext(EntityAndManagerContext);

  function getLoadingState(entities: any[]): boolean {
    return entities.length === 0;
  }

  const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
      { id: "site", visible: true },
      { id: "region", visible: true },
      { id: "country", visible: true },
      { id: "survey_status", visible: true },
      { id: "defect_status_color", visible: true },
    ]
  };

  const [preferences, setPreferences] = useLocalStorage<{
    pageSize: number;
    contentDisplay: readonly ContentDisplayItem[];
  }>('PrimaryView-EntityTable-Preferences', DEFAULT_PREFERENCES);

  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(entities, {
    filtering: {
      empty: (
        <EmptyState
          title="No Entities Found"
          action={<Button>Create Entity</Button>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
      filteringFunction: (item, filteringText) => {
        return item.region.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.country.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.site.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase());
      },
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  if(isLoading) {
    return <EmptyState title="Loading sites..." subtitle="" action="" />
  }

  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={(evt) => {
          setSelectedLocation(evt.detail.selectedItems[0]);
          clearMessages();
        }}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
            } selected`,
        }}
        columnDefinitions={[
          {
            id: "site",
            header: "Site name",
            cell: (e: EntityType) => <Button variant="link" onClick={() => {
              setSelectedLocation(e);
              setInTabbedView(true);
            }}>{e.site}</Button>,
            sortingField: "site",
            isRowHeader: true,
          },
          {
            id: "region",
            header: "Region",
            cell: (e: EntityType) => e.region,
            sortingField: "region",
          },
          {
            id: "country",
            header: "Country",
            cell: (e: EntityType) => e.country,
          },
          {
            id: "survey_status",
            header: "Controls survey",
            cell: (e: EntityType) => {
              let status = e.site === "" ? "" : "Not submitted";
              let color = "red";
              if (e.survey_partially_completed) {
                color = "orange";
                status = "In progress";
              } else if (e.survey_completed) {
                status = "Completed";
                color = "green"
              }
              return (<Box><span style={{color: color}}>{status}</span></Box>);
            },
          },
          {
            id: "defect_status_color",
            header: <Popover
              fixedWidth
              header="Assurance status description"
              size="large"
              content={
                <ul className="no-list-style">
                  <li><div style={{ color: RED, width: "60px", display: "inline-block" }}>Red:</div> Overdue</li>
                  <li><div style={{ color: YELLOW, width: "60px", display: "inline-block" }}>Yellow:</div> Due in next 30 days</li>
                  <li><div style={{ color: GREEN, width: "60px", display: "inline-block" }}>Green:</div>  No defects due in next 30 days</li>
                </ul>
              }          
            >
             <strong>Assurance</strong>
            </Popover>,
            cell: (e: EntityType) => {
              switch (e.defect_status_color) {
                case "red": return <StatusLight color={RED} />;
                case "yellow": return  <StatusLight color={YELLOW} />;
                default: return  <StatusLight color={GREEN} />;
              }
            },
            width: 80
          },
        ]}
        columnDisplay={preferences.contentDisplay}
        enableKeyboardNavigation
        items={itemsFromUseCollection}
        loading={getLoadingState(entities)}
        loadingText="Loading sites"
        trackBy="site"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Search sites"
            filteringAriaLabel="Filter sites"
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => {
              const { pageSize, contentDisplay } = detail;
              if (typeof pageSize === 'number' && contentDisplay) {
                setPreferences({ pageSize, contentDisplay });
              }
            }}
            pageSizePreference={{
              title: "Page Size",
              options: [
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 50, label: "50" },
                { value: 100, label: "100" },
              ],
            }}
            contentDisplayPreference={{
              options: [
                {
                  id: "site",
                  label: "Site Name",
                  alwaysVisible: true,
                },
                { id: "region", label: "Region" },
                { id: "country", label: "Country" },
                { id: "survey_status", label: "Controls survey" },
                { id: "defect_status_color", label: "Assurance status" },
              ],
            }}
          />
        }
      />
        <AddSiteModal />
    </>
  );
};
