import React, { useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { ModalContext } from "../../useModal";
import { Header, Table } from "@amzn/awsui-components-react";
import { DefenseInDepthControlType } from "../../../types";
import { SiteControlsContext } from "../useSiteControls";

const ReadOnlyControlSetForRiskModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);
  const { controlsNotUsedAtCurrentSite,
    nDelayControlsNotUsed,
    nDeterControlsNotUsed,
    nDetectControlsNotUsed,
    nRecoverControlsNotUsed,
    nRespondControlsNotUsed,
    selectedRiskName } = useContext(SiteControlsContext);
  const { items: itemsFromUseCollection, collectionProps } = useCollection(controlsNotUsedAtCurrentSite, {
    sorting: {},
    selection: {},
  });

  return (
    <Modal
      header={<Header
        description="These are controls in the control set that are not available on site."
      >Controls not used to mitigate <strong>{selectedRiskName}</strong></Header>}
      onDismiss={() => hideModals()}
      size="max"
      visible={isShowing("UnusedControlSetForRiskModal")}
    >
      <Table
        {...collectionProps}
        columnDefinitions={[
          {
            id: "controlName",
            header: "Control",
            cell: (e: DefenseInDepthControlType) => (<span className={e.isUsedAtSite ? "" : "muted"}>{e.controlName}</span>),
            sortingField: "controlName",
            isRowHeader: true,
            width: 100,
          },
          {
            id: "deter",
            header: <span>Deter ({nDeterControlsNotUsed})</span>,
            cell: (e: DefenseInDepthControlType) => (<span>{e.deter === 1 ? "x" : ""}</span>),
            sortingField: "deter",
            isRowHeader: true,
            width: 60,
          },
          {
            id: "detect",
            header: <span>Detect ({nDetectControlsNotUsed})</span>,
            cell: (e: DefenseInDepthControlType) => (<span>{e.detect === 1 ? "x" : ""}</span>),
            sortingField: "detect",
            isRowHeader: true,
            width: 60,
          },
          {
            id: "delay",
            header: <span>Delay ({nDelayControlsNotUsed})</span>,
            cell: (e: DefenseInDepthControlType) => (<span>{e.delay === 1 ? "x" : ""}</span>),
            sortingField: "delay",
            isRowHeader: true,
            width: 60,
          },
          {
            id: "respond",
            header: <span>Respond ({nRespondControlsNotUsed})</span>,
            cell: (e: DefenseInDepthControlType) => (<span>{e.respond === 1 ? "x" : ""}</span>),
            sortingField: "respond",
            isRowHeader: true,
            width: 60,
          },
          {
            id: "recover",
            header: <span>Recover ({nRecoverControlsNotUsed})</span>,
            cell: (e: DefenseInDepthControlType) => (<span>{e.recover === 1 ? "x" : ""}</span>),
            sortingField: "recover",
            isRowHeader: true,
            width: 60,
          },
        ]}
        enableKeyboardNavigation
        stripedRows
        items={itemsFromUseCollection}
        loadingText="Loading controls"
      />
    </Modal >
  );
};

export default ReadOnlyControlSetForRiskModal;
