import React, { useContext, useEffect, useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Button from "@amzn/awsui-components-react/polaris/button";
import { useCollection } from "@amzn/awsui-collection-hooks";
import EmptyState from "../../EmptyState";
import { SelectedSiteContext } from "src/components/useSelectedSite";
import { SiteDefectDisplayType } from "../../../types";
import { SiteDefectsContext } from "../useDefects";
import { Box, ColumnLayout, Container, Icon, Link, Pagination, Popover } from "@amzn/awsui-components-react";
import { ASSURANCE_PAGE_BASE_URL, paginationLabels, RED, YELLOW, GREEN } from "../../../constants";

const DefectsTable = () => {
  const { defectsForCurrentSite, isLoading } = useContext(SiteDefectsContext);
  const { site } = useContext(SelectedSiteContext);
  const [localLoadingText, setLocalLoadingText] = useState("loading")

  useEffect(() => {

    setLocalLoadingText(isLoading ? "loading" : "")
  }, [isLoading]);

  const {
    items: itemsFromUseCollection,
    actions,
    collectionProps,
    paginationProps,
  } = useCollection(defectsForCurrentSite, {
    filtering: {
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: 10 },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'startDate',
        },
        isDescending: false
      }
    },
    selection: {},
  });

  const getColorForDueDate = (colorCode: string): string => {
    switch (colorCode) {
      case "red":
        return RED;
      case "yellow":
        return YELLOW;
      case "green":
        return GREEN;
      default:
        return "inherit";
    }
  }

  return (isLoading ? <span></span> : <Table

    header={<h3>{site} Defects</h3>}
    {...collectionProps}

    ariaLabels={{
      selectionGroupLabel: "Items selection",
      allItemsSelectionLabel: ({ selectedItems }) =>
        `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
        } selected`,
    }}
    columnDefinitions={[
      {
        id: "link",
        header: "Defect link",
        cell: (e: SiteDefectDisplayType) => <Link href={`${ASSURANCE_PAGE_BASE_URL}${e.defectId}`} target="_blank">{e.defectId}</Link>,
        sortingField: "defectId",
        isRowHeader: true,
        width: 100,
        minWidth: 90
      },
      {
        id: "auditType",
        header: <strong>Audit type</strong>,
        cell: (e: SiteDefectDisplayType) => e.auditType,
        sortingField: "auditType",
        isRowHeader: true,
        width: 100,
        minWidth: 90
      },
      {
        id: "defectOwnerCategory",
        header: <strong>Defect owner category</strong>,
        cell: (e: SiteDefectDisplayType) => e.defectOwnerCategory,
        sortingField: "defectOwnerCategory",
        isRowHeader: true,
        width: 100,
        minWidth: 90
      },
      {
        id: "dueDate",
        header: <Popover
          header="Due date color legend"
          content={
            <ul className="no-list-style">
              <li><div style={{ color: RED, width: "60px", display: "inline-block" }}>Red:</div> Overdue</li>
              <li><div style={{ color: YELLOW, width: "60px", display: "inline-block" }}>Yellow:</div> Due in next 30 days</li>
              <li><div style={{ color: GREEN, width: "60px", display: "inline-block" }}>Green:</div>  No defects due in next 30 days</li>
            </ul>
          }
        >
          <strong>Due</strong>
        </Popover>,
        cell: (e: SiteDefectDisplayType) => {
          return <span style={{ color: getColorForDueDate(e.color) }}>{e.dueDate}</span>;
        },
        sortingField: "dueDate",
        isRowHeader: true,
        width: 120,
        minWidth: 90
      },
      {
        id: "defect",
        header: <strong>Defect</strong>,
        cell: (e: SiteDefectDisplayType) => (
          <div className="word-break">{
              (e.defect.length > 256) ?
                <Popover
                  dismissButton={true}
                  position="top"
                  size="large"
                  triggerType="custom"
                  content={e.defect}
                >
                  {e.defect.substring(0, 256)}... <Icon name="status-info" />
                </Popover>
                :
                e.defect
            }
          </div>
        )
        ,
        sortingField: "defect",
        isRowHeader: true,
        minWidth: 190,
        width: "50%"
      },
      {
        id: "actionRequired",
        header: <strong>Action required</strong>,
        cell: (e: SiteDefectDisplayType) => (
          <div className="word-break">{
              (e.actionRequired.length > 256) ?
                <Popover
                  dismissButton={true}
                  position="top"
                  size="large"
                  triggerType="custom"
                  content={e.actionRequired}
                >
                  {e.actionRequired.substring(0, 256)}... <Icon name="status-info" />
                </Popover>
                :
                e.actionRequired
            }</div>
        )
        ,
        sortingField: "defect",
        isRowHeader: true,
        minWidth: 190,
        width: "50%"
      },
    ]}
    empty={!isLoading && `No defects found for ${site}`}
    loading={isLoading}
    items={itemsFromUseCollection}
    loadingText={localLoadingText}
    pagination={
      <Pagination {...paginationProps} ariaLabels={paginationLabels} />
    }
    wrapLines
  />);
}

export default DefectsTable;