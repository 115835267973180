import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  ColumnLayout,
  Flashbar,
  Header,
  Icon, 
  Modal,
  Popover,
  Select,
  SelectProps,
  SpaceBetween,
  TextFilter,
  Table,
} from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";

import EmptyState from "../../EmptyState";
import { SelectedSiteContext } from "../../useSelectedSite";
import { ModalContext } from "../../useModal";
import { ControlsSurveyContext } from "../../ControlsSurveyProvider";
import { EntityAndManagerContext } from "../../EntityAndManagerProvider";
import { IsUtilizedOptions, IsUtilizedValues, EffectivenessOptions } from "src/constants";
import EffectivenessDefinitionsLayout from "../../EffectivenessDefinitionsLayout";
import { getMatchesCountText } from "../../../utils";
import CancelWarning from "../../CancelWarning";
import {  SurveyViewOfControlType } from "src/types";
import { ControlDataContext } from "../../ControlsProvider";

const ControlsRegisterModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);
  const { selectedEntity } = useContext(SelectedSiteContext);
  const { controlCategories } = useContext(ControlDataContext);
  const { copyFromExistingSurvey, refetchSurveyForSelectedSite, state, dispatch, postSurveyAnswers } = useContext(ControlsSurveyContext);
  const { entities, refreshEntitiesAndManagers } = useContext(EntityAndManagerContext);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [showChangesWarning, setShowChangesWarning] = useState(false);
  const [hasSeenUnsavedChangesWarning, setHasSeenUnsavedChangesWarning] = useState(false);
  const [warningText, setWarningText] = useState('');

  const [selectedCopyFromOption, setSelectedCopyFromOption] = useState<SelectProps.Option>();

  useEffect(() => {
    setShowChangesWarning(false);

  }, [selectedEntity.entityId]);

  const getCellStyle = (control: SurveyViewOfControlType) => {
    if (control.control_name === "") return { display: "none" };

    if (control.isFirstOfCategory) {
      return { display: "inline" };
    } else {
      return { display: "none" };
    }
  };


  const onCancelEdit = () => {
    if (hasPendingChanges && !hasSeenUnsavedChangesWarning) {
      setWarningText('You have made changes that aren\'t saved. Click "Save" to keep your changes or "Cancel" to discard them.');
      setShowChangesWarning(hasPendingChanges);
      setHasSeenUnsavedChangesWarning(true);
      return false;
    }
    setHasPendingChanges(false);
    hideModals();
    setHasSeenUnsavedChangesWarning(false);
    setShowChangesWarning(false);
    refetchSurveyForSelectedSite();
  }
  const { items: itemsFromUseCollection, filteredItemsCount, collectionProps, filterProps } = useCollection(state.surveyQuestions, {
    filtering: {
      noMatch: (
        <EmptyState
          title="No Controls Found"
        />
      ),
      filteringFunction: (item, filteringText) => {
        return item.category_name.toLowerCase().includes(filteringText.toLowerCase())
          || item.control_name.toLowerCase().includes(filteringText.toLowerCase());
      },
    },
  });

  const copyFromOptions = entities
    .filter((e) => (e.survey_completed === 1))
    .sort((t1, t2) => t1.site.localeCompare(t2.site))
    .map((e) => {
      return { label: e.site, value: e.entityId };
    });

  copyFromOptions.unshift({ label: "Copy from", value: "" });

  const resetWarningState = () => {
    setHasPendingChanges(false);
    setShowChangesWarning(false);
    setHasSeenUnsavedChangesWarning(false);
  }

  return (<Modal
    size="large"
    onDismiss={() => {
      hideModals();
      resetWarningState();
      dispatch({Type: "UPDATE_SAVE_RESULT_MESSAGE", Cargo: ""});
    }}
    visible={isShowing("controlsRegisterModal")}
    footer={<div>
      <ColumnLayout columns={2}>
        <Box float="left">
          {state.lastUpdated != "" ? `Last Updated: ${state.lastUpdated}` : ""}
        </Box>
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => {
              onCancelEdit();
            }}
              disabled={!hasPendingChanges}>
              Cancel
            </Button>

            <Button
              disabled={!hasPendingChanges || state.surveyQuestions.some((s) => (s.is_utilized === -1 || s.effective === -1))}
              variant="primary"
              onClick={(_event) => {
                setShowChangesWarning(false);
                if (state.surveyQuestions.some((s) => (s.is_utilized === -1 || s.effective === -1))) {
                  setWarningText('All fields must be completed before submitting. Click "Save" to save your work');
                  setShowChangesWarning(true);
                  return false;
                }
                postSurveyAnswers();
                setHasPendingChanges(false);
                refreshEntitiesAndManagers()
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      </ColumnLayout>
      <Box margin={{ top: "l" }}>
        <CancelWarning showWarning={showChangesWarning}
          warningText={warningText}
          onDismiss={() => resetWarningState()}
        />
      </Box>
      {state.saveResultMessage !== "" && <Box margin={{ top: "l" }}>
        <Flashbar items={[
          {
            content: `${selectedEntity.site}: ${state.saveResultMessage}`,
            dismissible: true,
            onDismiss: (_evt) => {
              dispatch({ Type: "UPDATE_SAVE_RESULT_MESSAGE", Cargo: "" })
            },
            id: "message_1",
            type: state.saveResultMessage.indexOf("fail") === -1 ? "success" : "error"
          },
        ]} />
      </Box>

      }
    </div>
    }>

    <Box>
      <Table
        {...collectionProps}
        columnDefinitions={[
          {
            id: "category_type_id",
            header: "Control category",
            cell: (e: SurveyViewOfControlType) => <span style={getCellStyle(e)}>{controlCategories.find((cc) => cc.id === e.category_type_id)?.name}</span>,
            isRowHeader: true,
          },
          {
            id: "control",
            header: "Control name",
            cell: (e: SurveyViewOfControlType) => <Popover
              dismissButton={true}
              position="top"
              size="large"
              triggerType="custom"
              content={e.generalDescription}
            >
              {e.control_name} <Icon name="status-info" />
            </Popover>,
            isRowHeader: true,
          },
          {
            id: "owner",
            header: "Owner",
            cell: (e: SurveyViewOfControlType) => e.owner,
          },
          {
            id: "is_utilized",
            header: <Popover
              fixedWidth
              header="Is utilized?"
              size="large"
              content={
                <div>
                  <div><b>N/A</b> - Not applicable</div>
                  <div><b>No</b> - Control is not available at the site but should be</div>
                  <div><b>Partial</b> - Partial utilization/ deployment</div>
                  <div><b>Yes</b> - Control is actively deployed at the site</div>
                </div>
              }              
            >
              <strong>Is utilized?</strong>
            </Popover>,
            cell: (e: SurveyViewOfControlType) => <Select
              selectedOption={IsUtilizedOptions.filter((f) => parseInt(f.value) == e.is_utilized)[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== "-1") {
                  dispatch({
                    Type: "UPDATE_ENTITY_CONTROL_IS_UTILIZED", Cargo: {
                      isUtilized: parseInt(detail.selectedOption.value!),
                      controlId: e.control_id
                    }
                  });
                  setHasPendingChanges(true);
                  setShowChangesWarning(false);
                  setWarningText('');
                }
              }
              }
              options={IsUtilizedOptions}
            />,
            isRowHeader: true,
            width: 180,
          },
          {
            id: "effective",
            header: <Popover
              fixedWidth
              header="Control Effectiveness Definitions"
              size="large"
              position="bottom"
              content={
                <EffectivenessDefinitionsLayout />
              }
            ><strong>Effectiveness Rating</strong></Popover>,
            cell: (item: SurveyViewOfControlType) => {

              const optionsToShow = (item.is_utilized == IsUtilizedValues.PARTIAL || item.is_utilized == IsUtilizedValues.YES) ? EffectivenessOptions.filter((eo) => eo.value !== "0") : EffectivenessOptions;
              const selOption = EffectivenessOptions.find((efOpt) => parseInt(efOpt.value) == item.effective)!;
              return (<Select
                disabled={item.is_utilized === IsUtilizedValues.NOT_SET
                  || item.is_utilized === IsUtilizedValues.N_A
                  || item.is_utilized === IsUtilizedValues.NO}
                options={optionsToShow}
                selectedOption={selOption}
                placeholder={EffectivenessOptions.find((efOpt) => efOpt.value == item.effective.toString())?.label}
                onChange={(e) => {
                  //Don't allow setting the value to "choose effectiveness"
                  if (e.detail.selectedOption.value !== "-1") {
                    setHasPendingChanges(true);
                    dispatch({ Type: "UPDATE_ENTITY_CONTROL_EFFECTIVENESS", Cargo: { effectiveness: parseInt(e.detail.selectedOption.value!), controlId: item.control_id } });
                  }
                  setShowChangesWarning(false);
                  setWarningText('');
                }}
              />)
            },
            isRowHeader: true,
          }
        ]}
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Select
                  selectedOption={selectedCopyFromOption!}
                  onChange={({ detail }) => {
                    setSelectedCopyFromOption((copyFromOptions.find((co) => co.value === detail.selectedOption.value)));
                  }}
                  options={copyFromOptions}
                  filteringType="auto"
                  placeholder="Copy from another survey"
                />
                <Button onClick={(_evt) => {
                  copyFromExistingSurvey(selectedCopyFromOption?.value || "");
                  setHasPendingChanges(true);
                }}>Import</Button>
              </SpaceBetween>}
          >
            <span>{selectedEntity.site} controls register</span>
          </Header>}

        enableKeyboardNavigation
        items={itemsFromUseCollection}
        loadingText="Loading resources"
        wrapLines
        trackBy="control_id"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find a control"
            filteringAriaLabel="Filter controls"
          />
        }
      />


    </Box>
  </Modal>
  );
};

export default ControlsRegisterModal;
