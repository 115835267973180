import React, { useContext, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AppLayout,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  Header,
  SideNavigation,
  SideNavigationProps,
} from "@amzn/awsui-components-react/polaris";
import { I18nProvider } from "@amzn/awsui-components-react/polaris/i18n";
import messages from "@amzn/awsui-components-react/polaris/i18n/messages/all.en";

import MetricsPanel from "./MetricsPanel";

import ControlsTableForWorkbench from "./ControlsTableForWorkbench";
import ReadOnlyControlsTableForWorkbench from "./ReadOnly/ReadOnlyControlsTableForWorkbench";
import ReadOnlyRiskRegister from "./ReadOnly/ReadOnlyRiskRegister";
import RiskTableForWorkbench from "./RiskTableForWorkbench";
import IncidentsTabsForWorkbench from "./incidents/IncidentTabsForWorkbench";
import { EditIncidentContextProvider } from "./incidents/EditIncident/useEditIncident";
import ViewIncidentModal from "./incidents/ViewIncidentModal";

import EditIncidentModal from "./incidents/EditIncident/EditIncidentModal";
import EditControlModal from "./EditControlModal";

import EditSubRiskModal from "./EditSubRiskModal";
import CreateControlModal from "./CreateControlModal";
import ControlDetailsModal from "./ReadOnly/ControlDetailsModal";
import ReadOnlyControlSetForRiskModal from "../components/ReadOnly/ReadOnlyControlSetForRiskModal";
import ControlSetEditorForRiskModal from "./ControlSetEditorForRiskModal";
import { RoleContext } from "./RoleProvider";
import AsmsAndRsmsTable from "./AsmsAndRsms/AsmsAndRsmsTable"
import { RegionsAorsCountriesProvider } from "./AsmsAndRsms/useRegionsAorsCountries";
import { FlashMessageContext } from "./useFlashMessages";
import { SiteContextProvider } from "./HomePage/useNewTabbedView";
import { SiteDefectsProvider } from "./HomePage/useDefects";
import { SiteControlsProvider } from "./HomePage/useSiteControls";
import { SiteLogProvider } from "./HomePage/useSiteLog";
import HomePageSwitcher from "./HomePage/HomePageSwitcher";
import ReadOnlyControlsTableInstructionsModal from "./ReadOnly/ReadOnlyControlsTableInstructionsModal";
import ReadOnlyControlsTableScmpModal from "./ReadOnly/ReadOnlyControlsTableScmpModal";
import ReadOnlyRiskRegisterGetStartedModal from "./ReadOnly/ReadOnlyRiskRegisterGetStartedModal";
import { ModalContext } from "./useModal";

const LOCALE = "en";

const PrimaryAppLayout = () => {
  const [navOpen, setNavOpen] = useState(false);

  const { userIsRiskAdmin, userIsViewOnly } = useContext(RoleContext);
  const { showModal } = useContext(ModalContext);
  const { showSuccess, setShowSuccess, flashMessage } = useContext(FlashMessageContext);

  const navOptions: SideNavigationProps.Item[] = [{ type: "link", text: "Landing page", href: "/" }];
  if (userIsRiskAdmin) {
    navOptions.push({ type: "link", text: "Global physical security threat register (workbench)", href: "/globalRiskRegister" });
    navOptions.push({ type: "link", text: "Global physical security controls register (workbench)", href: "/globalControlsRegister" });
    navOptions.push({ type: "link", text: "Security incident database (workbench)", href: "/securityIncidentDatabase" });
    navOptions.push({ type: "link", text: "Amazon Corporate Security (ACS) contacts", href: "/SecurityManagers" })
  }

  return (
    <>
      <I18nProvider locale={LOCALE} messages={[messages]}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  navigationHide={userIsViewOnly}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={userIsViewOnly ? <span></span> :
                    <SideNavigation
                      header={{
                        href: '#/',
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  notifications={showSuccess && <Flashbar
                    items={[
                      {
                        type: 'success',
                        dismissible: true,
                        content: flashMessage,
                        id: 'message_1',
                        onDismiss: () => {
                          setShowSuccess(false);
                        }
                      },
                    ]}

                  />}

                  content={<>
                    <ViewIncidentModal />
                    <ContentLayout
                      disableOverlap
                    >
                      <MetricsPanel />
                      <SiteContextProvider>
                        <SiteDefectsProvider>
                          <SiteControlsProvider>
                            <SiteLogProvider>
                              <HomePageSwitcher />
                            </SiteLogProvider>
                          </SiteControlsProvider>
                        </SiteDefectsProvider>
                      </SiteContextProvider>
                    </ContentLayout>
                  </>}
                />}
            />
            <Route
              path="/securityIncidentDatabase"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '#/',
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>
                    <ViewIncidentModal />
                    <EditIncidentContextProvider>
                      <EditIncidentModal />
                    </EditIncidentContextProvider>

                    <Container
                      header={
                        <Header variant="h1">
                          <span className="poseiden">Security incident database (workbench)</span>
                        </Header>
                      }
                    >
                      <IncidentsTabsForWorkbench />
                    </Container>
                  </>}
                />}
            />
            <Route
              path="/globalControlsRegister"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '#/',
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>
                    <EditControlModal />
                    <CreateControlModal />
                    <Container
                      header={<Header variant="h2"> <span className="poseiden">Global physical security controls register (workbench)</span></Header>} variant="stacked"
                    >
                      <ControlsTableForWorkbench />
                    </Container>
                  </>}
                />}
            />
            <Route
              path="/viewOnlyControlsRegister"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '#/',
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>

                    <ReadOnlyControlsTableInstructionsModal />
                    <ReadOnlyControlsTableScmpModal />
                    <ControlDetailsModal />
                    <Container
                      header={<ColumnLayout columns={2}>
                        <h2 className="poseiden">Global physical security controls register</h2>
                        <div style={{ float: "right", marginTop: "12px", verticalAlign: "middle" }}><Button onClick={() => {
                          showModal("readOnlyControlsTableSCMP");
                        }}>Security controls managment plan</Button></div>
                        <div><Button onClick={() => {
                          showModal("readOnlyControlsTableInstructionsModal");
                        }}>Get started</Button></div>
                      </ColumnLayout>} variant="stacked"
                    >
                      <ReadOnlyControlsTableForWorkbench />
                    </Container>
                  </>}
                />}
            />
            <Route
              path="/viewOnlyRiskRegister"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '#/',
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>
                    <ReadOnlyRiskRegisterGetStartedModal />
                    <ReadOnlyControlSetForRiskModal />
                    <Container
                      header={<>
                        <h2 className="poseiden">Global physical security threat register</h2>
                        <div><Button onClick={() => {
                          showModal("readOnlyRiskRegisterGetStartedModal");
                        }}>Get started</Button></div>
                      </>
                      }
                      variant="stacked"
                    >
                      <ReadOnlyRiskRegister />
                    </Container>
                  </>}
                />}
            />
            <Route
              path="/globalRiskRegister"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '#/',
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>
                    <EditSubRiskModal />
                    <Container
                      header={<Header variant="h2"><span className="poseiden">Global physical security threat register (workbench)</span></Header>} variant="stacked"
                    >
                      <RiskTableForWorkbench />
                      <ControlSetEditorForRiskModal />
                    </Container>

                  </>}
                />}
            />


            <Route
              path="/SecurityManagers"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: "#/",
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>
                    <Container
                      header={<Header><span className="poseiden">Amazon Corporate Security (ACS) contacts</span></Header>}
                    >
                      <RegionsAorsCountriesProvider>
                        <AsmsAndRsmsTable />
                      </RegionsAorsCountriesProvider>
                    </Container>
                  </>}
                />}
            />
          </Routes>
        </BrowserRouter>
      </I18nProvider>
    </>
  );
}

export default PrimaryAppLayout;
