import React, { useContext } from "react";
import {
    Container,
} from "@amzn/awsui-components-react/polaris";

import { SiteContext } from "./useNewTabbedView";
import { SiteEventsProvider } from "./useEvents";
import PrimaryView from "../PrimaryView";
import SiteContainer from "./SiteContainer";
import { RoleContext } from "../RoleProvider";

const HomePageSwitcher = () => {
    const { inTabbedView } = useContext(SiteContext);
    const { userIsViewOnly } = useContext(RoleContext);

    if (userIsViewOnly) return <span></span>;

    return (
        <SiteEventsProvider>
            <Container>
                {!inTabbedView && <PrimaryView />}
                {inTabbedView && <SiteContainer />}
            </Container>
        </SiteEventsProvider>)
}

export default HomePageSwitcher;