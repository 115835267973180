import React, { useContext } from "react";
import { Box, Button, ColumnLayout, Container, SpaceBetween } from "@amzn/awsui-components-react";
import SiteCounts from "./HomePage/SiteCounts";
import { RoleContext } from "./RoleProvider";

export default () => {

  const timeWashingtonDC = new Date().toLocaleTimeString('en-US', { timeZone: 'America/New_York', hour: "numeric", minute: "numeric" });
  const timeLondon = new Date().toLocaleTimeString('en-US', { timeZone: 'Europe/London', hour: "numeric", minute: "numeric" });
  const timeBangalore = new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata', hour: "numeric", minute: "numeric" });
  const timeSeattle = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour: "numeric", minute: "numeric" });
  const { userIsViewOnly } = useContext(RoleContext);

  return (
    <Container>
      <ColumnLayout columns={userIsViewOnly ? 2 : 3} variant="text-grid" minColumnWidth={170}>
          {!userIsViewOnly && <SiteCounts />}

          <Box padding={{top: 'xl',}}>
          <SpaceBetween size="xxl" alignItems="center">
            <Button variant="link" href="/viewOnlyRiskRegister">Global Physical Security Threat Register</Button>
            <Button variant="link" href="/viewOnlyControlsRegister">Global Physical Security Controls Register</Button>
          </SpaceBetween>
          </Box>
          
          <SpaceBetween size="m">
            <Box textAlign="right">Washington DC: <strong>{timeWashingtonDC}</strong></Box>
            <Box textAlign="right">Seattle: <strong>{timeSeattle}</strong></Box>
            <Box textAlign="right">Bangalore: <strong>{timeBangalore}</strong></Box>
            <Box textAlign="right">London: <strong>{timeLondon}</strong></Box>
          </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};