import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { SelectedSiteContext } from "../useSelectedSite";
import { SiteDefectDBType, SiteDefectDisplayType, SiteDefectsDictionary } from "src/types";
import useSecuredFetch from "../useSecuredFetch";
import { getBaseUrl, getMonthDayAndYear } from "src/utils";

export type DefectsContextType = {
    defectsForCurrentSite: SiteDefectDisplayType[],
    isLoading: boolean
}

export const SiteDefectsContext = createContext<DefectsContextType>({ defectsForCurrentSite: [], isLoading: false });

export const SiteDefectsProvider = (props: { children: JSX.Element }) => {
    const { site, selectedEntity } = useContext(SelectedSiteContext);
    const secureFetch = useSecuredFetch();

    const [defectsForAllSites, setDefectsForAllSites] = useState<SiteDefectsDictionary>({});
    const [defectsForCurrentSite, setDefectsForCurrentSite] = useState<SiteDefectDisplayType[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchFxn = async () => {
            setDefectsForCurrentSite([]);
            if (site !== "") {
                if (defectsForAllSites[site]?.wasFetched) {
                    setIsLoading(false);
                    setDefectsForCurrentSite(defectsForAllSites[site].defects);
                } else {
                    setIsLoading(true)
                    const newDictionary = { ...defectsForAllSites };
                    const result = await secureFetch(`${getBaseUrl()}/defects?entity=${selectedEntity.entityId}`, 'GET');
                    const json = await result.json();
                    const defectsToDisplay = json.defects.map((a: SiteDefectDBType) => {
                        return {
                            actionRequired: a.actionrequired,
                            color: a.color,
                            defectId: a.itemid,
                            defectOwnerCategory: a.defectownercategory,
                            auditType: a.audittype,
                            dueDate: getMonthDayAndYear(a.duedate),
                            dueDateBucket: a.due_date_bucket,
                            defect: a.defect
                        }
                    });
                    newDictionary[site] = {
                        defects: defectsToDisplay,
                        wasFetched: true
                    };
                    setDefectsForAllSites(newDictionary);
                    setDefectsForCurrentSite(defectsToDisplay);
                    setIsLoading(false);
                }
            }
        }
        fetchFxn();

    }, [selectedEntity.site]);

    return (<SiteDefectsContext.Provider value={{ defectsForCurrentSite, isLoading }}>
        {props.children}
    </SiteDefectsContext.Provider>)
}