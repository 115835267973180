import { createContext, Dispatch, useContext, useEffect, useReducer, useState } from "react";
import { RiskType, SubRiskType, ReducerAction, NumberToStringDictionary, ControlCriticalitiesByRiskType } from "src/types";
import { EmptyRisk, EmptySubRisk } from "src/constants";
import { RoleContext } from "./RoleProvider";
import useSecuredFetch from "./useSecuredFetch";
import { getBaseUrl } from "src/utils";

export type RiskContextType = {
  controlCriticalitiesByRisk: ControlCriticalitiesByRiskType;
  risks: RiskType[];
  subRisks: SubRiskType[],
  selectedSubRisk: SubRiskType,
  selectedRiskCategory: RiskType;
  nextSubRiskIds: NumberToStringDictionary;
}

function Reducer(state: RiskContextType, action: ReducerAction) {
  switch (action.Type) {
    case "UPDATE_RISKS_AND_SUBRISKS": {
      return {
        ...state,
        risks: action.Cargo.risks,
        subRisks: action.Cargo.subRisks
      };
    }
    case "SET_SELECTED_RISK_CATEGORY": {
      return {
        ...state,
        selectedRiskCategory: state.risks.filter((riskCategory) => riskCategory.risk_number == action.Cargo.riskCategoryId)[0]
      };
    }
    case "SET_SELECTED_SUBRISK": {
      return {
        ...state,
        selectedSubRisk: state.subRisks.filter((sr) => sr.acs_risk_dim_sk == action.Cargo.acs_risk_dim_sk)[0]
      };
    }
    case "SET_SELECTED_SUBRISK_TO_EMPTY": {
      return {
        ...state,
        selectedSubRisk: { ...EmptySubRisk }
      };
    }

    case "SET_SELECTED_RISK_CATEGORY": {
      return {
        ...state,
        selectedRiskCategory: state.risks.filter((x) => x.risk_number === action.Cargo.riskID)[0]
      };
    }

    case "SET_SELECTED_RISK_CATEGORY_TO_EMPTY": {
      return {
        ...state,
        selectedRiskCategory: EmptyRisk
      };
    }

    case "UPDATE_NEXT_LETTERS": {
      return {
        ...state,
        nextSubRiskIds: action.Cargo.newDictionary
      }
    }

   
  }
  return state;
}


export const RiskContext = createContext<{
  state: RiskContextType, dispatch: Dispatch<ReducerAction>,
  createRisk: (_riskName: string, _isActive: number, _description: string) => void,
  updateRisk: (_riskNumber: number, _riskName: string, _isActive: number, _description: string) => void,
  deleteRisk: (_riskNumber: number) => void,
  createSubRisk: (
    parentRiskID: number,
    nextLetter: string,
    _newSubRiskName: string,
    _isActive: number,
    _newDescription: string,
    _isReportable: number,
    _newSpecificContext: string) => void,


  updateSubRisk: (_subRiskID: number,
    _newSubRiskName: string,
    _isActive: number,
    _newDescription: string,
    _isReportable: number,
    _newSpecificContext: string) => void,

  deleteSubRisk: (acs_risk_dim_sk: number) => void,
  getFullCategoryName: (subRiskName: string) => string,
  getFirstSubRiskId: (riskNumber: number) => string
}>(
  {
    state: {
      controlCriticalitiesByRisk: {},
      risks: [EmptyRisk],
      subRisks: [EmptySubRisk],
      selectedSubRisk: EmptySubRisk,
      selectedRiskCategory: EmptyRisk,
      nextSubRiskIds: {}
    },
    dispatch: () => null,
    createRisk: (_riskName: string, _isActive: number, _description: string) => null,
    updateRisk: (_riskNumber: number, _riskName: string, _isActive: number, _description: string) => null,
    deleteRisk: (_riskNumber: number) => null,
    createSubRisk: (_parentRiskID: number,
      _nextLetter: string,
      _newSubRiskName: string,
      _isActive: number,
      _newDescription: string,
      _isReportable: number,
      _newSpecificContext: string) => null,

    updateSubRisk: (_subRiskID: number, _newSubRiskName: string, _isActive: number, _newDescription: string,
      _isReportable: number, _newSpecificContext: string) => null,
    deleteSubRisk: (_acs_risk_dim_sk: number) => null,
    getFullCategoryName: (_subRiskName: string) => "",
    getFirstSubRiskId: (_riskNumber: number) => ""
  }
);

export const RiskContextProvider = (props: { children: JSX.Element }) => {
  const { children } = props;
  const { token } = useContext(RoleContext);
  const secureFetch = useSecuredFetch();
  //whenever this changes, I refetch the risks and subrisks
  const [fetchCounter, setFetchCounter] = useState(0);

  const [state, dispatch] = useReducer(Reducer, {
    controlCriticalitiesByRisk: {},
    risks: [EmptyRisk],
    subRisks: [EmptySubRisk],
    selectedSubRisk: EmptySubRisk,
    selectedRiskCategory: EmptyRisk,
    nextSubRiskIds: {}
  });

  const getNextLetter = (lastSubRiskId: string) => {
    const asciiCode = lastSubRiskId.charCodeAt(0);
    return String.fromCharCode(asciiCode + 1);
  }

  const getFullCategoryName = (subRiskName: string) => {
    if (subRiskName.length === 0) return "";
    const categoryIndex = parseInt(subRiskName[0]);
    let retVal = "";
    try {
      retVal = state.risks[categoryIndex - 1].risk_name_full;
    } catch (ex) {
      debugger;
    }
    return retVal;
  }

  //Handle the case where the first active risk in a category doesn't have the letter 'a' for a sub_risk_id.  
  const getFirstSubRiskId = (riskNumber: number) => {
    const firstDefinedSubRisk = state.subRisks.filter((r: SubRiskType) => r.risk_id === riskNumber)[0];
    return firstDefinedSubRisk.sub_risk_id;
  };

  useEffect(() => {
    async function fxn() {
      if (token.length > 0) {
        const response = await secureFetch(`${getBaseUrl()}/risks`, 'GET');
        const { risks, subRisks } = await response.json()
        dispatch({ Type: "UPDATE_RISKS_AND_SUBRISKS", Cargo: { risks, subRisks } });

        const newDictionary: NumberToStringDictionary = {}
        risks.map((risk: any) => {
          const subRisksRows = subRisks.filter((sr: SubRiskType) => {
            return sr.risk_id === risk.risk_number;
          });
          let nextLetter = "A";
          const sorted = subRisksRows?.sort((r: SubRiskType) => r.acs_risk_dim_sk);
          if (subRisksRows.length) {
            const lastSubRiskId = sorted[sorted.length - 1].sub_risk_id;
            nextLetter = getNextLetter(lastSubRiskId);
          }
          newDictionary[risk.risk_number] = nextLetter;
        })
        dispatch({ Type: "UPDATE_NEXT_LETTERS", Cargo: { newDictionary } });
      }
    }
    fxn();
  }, [token, fetchCounter])

  const createRisk = async (riskName: string, isActive: number, description: string) => {
    await secureFetch(`${getBaseUrl()}/risks`, "POST", JSON.stringify({
      riskName,
      isActive,
      description
    }));
    setFetchCounter((old) => old + 1);
  }

  const updateRisk = async (riskNumber: number, riskName: string, isActive: number, description: string) => {
    const res = await secureFetch(`${getBaseUrl()}/risks`, "PUT", JSON.stringify({
      riskNumber,
      riskName,
      isActive,
      description
    }));
    setFetchCounter((old) => old + 1);
  }

  const deleteRisk = async (riskNumber: number) => {
    //deletes a risk category
    const res = await secureFetch(`${getBaseUrl()}/risks`, "DELETE", JSON.stringify({
      riskNumber
    }));
    setFetchCounter((old) => old + 1);
  }

  const createSubRisk = async (parentRiskID: number, nextLetter: string, newSubRiskName: string, isActive: number, newDescription: string,
    isReportable: number, newSpecificContext: string) => {

    const res = await secureFetch(`${getBaseUrl()}/subRisk`, "POST", JSON.stringify({
      parentRiskID, nextLetter, newSubRiskName, isActive,
      newDescription, isReportable, newSpecificContext
    }));
    dispatch({ Type: "INCREMENT_NEXT_LETTER", Cargo: { parentRiskID } })
    setFetchCounter((old) => old + 1);
  }

  const updateSubRisk = async (subRiskID: number, newSubRiskName: string, isActive: number, newDescription: string,
    isReportable: number, newSpecificContext: string) => {

    const res = await secureFetch(`${getBaseUrl()}/subRisk`, "PUT", JSON.stringify({
      subRiskID, newSubRiskName, isActive,
      newDescription, isReportable, newSpecificContext
    }));

    setFetchCounter((old) => old + 1);
  }

  const deleteSubRisk = async (acs_risk_dim_sk: number) => {
    const res = await secureFetch(`${getBaseUrl()}/subRisk`, "DELETE", JSON.stringify({ acs_risk_dim_sk }));
    setFetchCounter((old) => old + 1);
  }


  return (
    <RiskContext.Provider value={{ state, dispatch, createRisk, deleteRisk, getFirstSubRiskId, getFullCategoryName, updateRisk, createSubRisk, updateSubRisk, deleteSubRisk }}>
      {children}
    </RiskContext.Provider>
  );
};
