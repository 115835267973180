import React, { createContext, useContext, useEffect, useState } from "react";

import { IncidentRiskDBType, IncidentRiskDisplayType, IncidentType } from "src/types";
import { emptyIncident, SESSION_TIMEOUT_ERROR } from "src/constants";
import { IncidentsContext } from "../IncidentsProvider";
import { RoleContext } from "../../RoleProvider";

import useSecuredFetch from "../../useSecuredFetch";
import { getBaseUrl } from "src/utils";

export type myFxnType = (n: number) => number;
export type setNumberStateType = (arg: myFxnType) => void

export type EditIncidentContextType = {
  addRiskToIncident: (risk: IncidentRiskDisplayType) => void;
  analystNotesValue: string;
  batchMode: boolean;
  bookmarkedValue: number;
  checkForErrors: () => boolean;
  cityValue: string;
  countryValue: string;
  dateReportedValue: string;
  decrementSelectedIndex: () => void,
  displayIDValue: string;
  errors: string[];
  errorLevel: string,
  incrementSelectedIndex: () => void,
  isDuplicateValue: number;
  locationValue: string;
  needsReviewLocal: number;
  notesValue: string;
  originalAnalystNotesValue: string;
  referenceRef: string;
  removeRiskFromIncident: (riskId: string) => void;
  regionValue: string;
  selectedIncidentIndex: number,
  selectedStatus: string;
  selectedSource: string;
  setAnalystNotesValue: (_notes: string) => void;
  setBookmarkedValue: (_bookmarked: number) => void;
  setCityValue: (_city: string) => void;
  setCountryValue: (_country: string) => void;
  setDateReportedValue: (_date: string) => void;
  setDisplayIDValue: (_displayId: string) => void;
  setErrors: (_err: string[]) => void;
  setIsDuplicateValue: (_isDupe: number) => void;
  setLocationValue: (_location: string) => void;
  setNeedsReviewLocal: (_needsReview: number) => void;
  setNotesValue: (_notes: string) => void;
  setOriginalAnalystNotesValue: (_notes: string) => void;
  setSelectedStatus: (_status: string) => void;
  setSelectedIncidentIndex: (index: number) => void;
  setReferenceRef: (_region: string) => void;
  setRegionValue: (_region: string) => void;
  setSelectedSource: (_source: string) => void;
  selectedSourceType: string;
  setSelectedSourceType: (_sourceType: string) => void;
  stateProvinceValue: string;
  setStateProvinceValue: (_stateProv: string) => void;
  subRiskDisplayTypesAssociatedWithIncident: IncidentRiskDBType[];
  setShowErrors: (_val: boolean) => void;
  setSubRiskDisplayTypesAssociatedWithIncident: (_subRisks: IncidentRiskDBType[]) => void;
  titleValue: string;
  setTitleValue: (_title: string) => void;
  triageStatus: string;
  setTriageStatus: (_title: string) => void;
  typeValue: string;
  setTypeValue: (_type: string) => void;
  showErrors: boolean;
  updateIncident: () => Promise<boolean>;
}

export const EditIncidentContext = createContext<EditIncidentContextType>({
  addRiskToIncident: (_risk: IncidentRiskDisplayType) => { },
  analystNotesValue: "",
  batchMode: false,
  setAnalystNotesValue: (_notes: string) => { },
  bookmarkedValue: 0,
  errorLevel: "warning",
  errors: [],
  setBookmarkedValue: (_bookmarked: number) => { },
  cityValue: "",
  checkForErrors: () => false,
  setCityValue: (_city: string) => { },
  countryValue: "",
  setCountryValue: (_country: string) => { },
  dateReportedValue: "",
  setDateReportedValue: (_date: string) => { },
  displayIDValue: "",
  setDisplayIDValue: (_displayId: string) => { },
  removeRiskFromIncident: (_riskId: string) => { },
  decrementSelectedIndex: () => { },
  incrementSelectedIndex: () => { },
  selectedIncidentIndex: 0,
  setSelectedIncidentIndex: (n: number) => { },
  setShowErrors: (_val: boolean) => { },
  isDuplicateValue: 0,
  setIsDuplicateValue: (_isDupe: number) => { },
  locationValue: "",
  setLocationValue: (_location: string) => { },
  showErrors: false,
  needsReviewLocal: 0,
  setNeedsReviewLocal: (_needsReview: number) => { },
  notesValue: "",
  setNotesValue: (_notes: string) => { },
  originalAnalystNotesValue: "",
  setOriginalAnalystNotesValue: (_notes: string) => { },
  referenceRef: "",
  setReferenceRef: (_ref: string) => { },
  regionValue: "",
  setErrors: (_err: string[]) => {},
  setRegionValue: (_region: string) => { },
  selectedStatus: "",
  setSelectedStatus: (_status: string) => { },
  selectedSource: "",
  setSelectedSource: (_source: string) => { },
  selectedSourceType: "",
  setSelectedSourceType: (_sourceType: string) => { },
  stateProvinceValue: "",
  setStateProvinceValue: (_stateProv: string) => { },
  subRiskDisplayTypesAssociatedWithIncident: [],
  setSubRiskDisplayTypesAssociatedWithIncident: (_subRisks: IncidentRiskDBType[]) => { },
  titleValue: "",
  setTitleValue: (_title: string) => { },
  triageStatus: "",
  setTriageStatus: (_title: string) => { },
  typeValue: "",
  setTypeValue: (_type: string) => { },
  updateIncident: () => (Promise.resolve(false))
});

export const EditIncidentContextProvider = (props: { children: JSX.Element }) => {
  const [selectedIncidentIndexInternal, setSelectedIncidentIndexInternal] = useState(0);
  const {  selectedIncidents, setSelectedIncidents, setNeedsRefetch  } = useContext(IncidentsContext);
  const { loggedInUser } = useContext(RoleContext);
  const batchMode = selectedIncidents.length > 1;
  const [cityValue, setCityValue] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [dateReportedValue, setDateReportedValue] = useState("");
  const [displayIDValue, setDisplayIDValue] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [needsReviewLocal, setNeedsReviewLocal] = useState(1);
  const [typeValue, setTypeValue] = useState("");
  const [analystNotesValue, setAnalystNotesValue] = useState("");
  const [originalAnalystNotesValue, setOriginalAnalystNotesValue] = useState("");
  const [notesValue, setNotesValue] = useState("");
  const [bookmarkedValue, setBookmarkedValue] = useState(0);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedSourceType, setSelectedSourceType] = useState("");
  const [triageStatus, setTriageStatus] = useState("raw");
  const [showErrors, setShowErrors] = useState(false);
  const [stateProvinceValue, setStateProvinceValue] = useState("");
  //this is the list of sub risks we show in the UI.  We add to it while they're clicking add, but these aren't saved to the DB till you save the whole incident
  const [subRiskDisplayTypesAssociatedWithIncident, setSubRiskDisplayTypesAssociatedWithIncident] = useState<IncidentRiskDBType[]>([]);
  const [referenceRef, setReferenceRef] = useState("");
  const [isDuplicateValue, setIsDuplicateValue] = useState(1);
  const [errors, setErrors] = useState<string[]>([]);
  const [errorLevel, setErrorLevel] = useState("warning");

  const secureFetch = useSecuredFetch();

  let thisIncident = selectedIncidents[selectedIncidentIndexInternal] || emptyIncident;

  useEffect(() => {
    const {
      analyst_notes,
      bookmarked,
      city,
      country,
      date_reported,
      display_id,
      duplicate,
      location,
      needs_review,
      notes,
      reference,
      region,
      state_province,
      status,
      source,
      source_type,
      threat_originator,
      title,
      type,
      triage_status
    } = thisIncident;

    setCityValue(city)
    setTitleValue(title);
    setDateReportedValue(date_reported);
    setDisplayIDValue(display_id);
    setTypeValue(type);
    setRegionValue(region);
    setCountryValue(country);
    setLocationValue(location);
    setAnalystNotesValue(analyst_notes);
    setOriginalAnalystNotesValue(analyst_notes);
    setNotesValue(notes);
    setBookmarkedValue(bookmarked)
    setNeedsReviewLocal(needs_review);
    setReferenceRef(reference);
    setIsDuplicateValue(duplicate);
    setTriageStatus(triage_status);
    setSelectedStatus(status);
    setSelectedSource(source);
    setSelectedSourceType(source_type);
    setStateProvinceValue(state_province);
  }, [selectedIncidentIndexInternal, thisIncident.id]);


      // PUTs a request to update the incident and refreshes the references to the incident in either the TriagedIncidents or UntriagedIncidents array
      const putIncident = async (updatedIncident: IncidentType, risksAssociatedWithThisIncident: IncidentRiskDBType[]) => {
          const response = await secureFetch(`${getBaseUrl()}/incident`, 'PUT', JSON.stringify({
              analystNotes: updatedIncident.analyst_notes,
              associatedRisks: risksAssociatedWithThisIncident,
              bookmarked: updatedIncident.bookmarked,
              city: updatedIncident.city,
              country: updatedIncident.country,
              duplicate: updatedIncident.duplicate,
              incidentID: updatedIncident.id,
              title: updatedIncident.title,
              type: updatedIncident.type,
              notes: updatedIncident.notes,
              location: updatedIncident.location,
              region: updatedIncident.region,
              needs_review: updatedIncident.needs_review,
              source: updatedIncident.source,
              stateProvince: updatedIncident.state_province,
              status: updatedIncident.status,
              sourceType: updatedIncident.source_type,
              triageStatus: updatedIncident.triage_status,
              threatOriginator: "Unknown",
              updatedBy: loggedInUser
          }));
  
          const result = await response.json();
  
          if (response.status == 200) {
            setNeedsRefetch();
            setErrorLevel("warning");
          } else if(response.status == 401){
            setErrors([SESSION_TIMEOUT_ERROR]);
            setErrorLevel("error");
            setShowErrors(true);
          }

          return response.status == 200;
      };

  const updateIncident = async () => {
    const associatedRisks = subRiskDisplayTypesAssociatedWithIncident.map((arn) => {
      return {
        incidentID: thisIncident.id,
        subRiskID: arn.subRiskID,
        isPrimary: arn.isPrimary
      }
    })

    const updatedIncident: IncidentType = {
      ...selectedIncidents[selectedIncidentIndexInternal],
      analyst_notes: (originalAnalystNotesValue !== analystNotesValue && analystNotesValue !== '') ? analystNotesValue : '',
      bookmarked: bookmarkedValue,
      city: cityValue || '',
      country: countryValue,
      id: thisIncident.id,
      date_reported: dateReportedValue,
      display_id: displayIDValue,
      duplicate: isDuplicateValue,
      reference: referenceRef,
      title: titleValue,
      type: typeValue,
      notes: notesValue,
      location: locationValue,
      region: regionValue,
      needs_review: needsReviewLocal,
      state_province: stateProvinceValue || '',
      status: selectedStatus || '',
      source: selectedSource || '',
      source_type: selectedSourceType || '',
      threat_originator: "Unknown",
      triage_status: triageStatus === "complete" ? "complete" : "n/a",
    }


    const result = await putIncident(updatedIncident, associatedRisks);
    if(result == true){
      if (!batchMode) {
        setSelectedIncidents([emptyIncident]);
      } else {
        const newSelectedIncidents = [...selectedIncidents];
        const indexToUpdate = newSelectedIncidents.findIndex((inc) => inc.id === updatedIncident.id);
        //so, this looks like the local copy of the incident will be updated, but not the DB
        newSelectedIncidents.splice(indexToUpdate,1, updatedIncident);
        setSelectedIncidents(newSelectedIncidents);
        thisIncident = updatedIncident;
      }
      setNotesValue("");//TODO: do I need this
    }

    return result;
   
  }// end updateIncident

  const incrementSelectedIndex = () => {
    if (selectedIncidentIndexInternal < selectedIncidents.length) {
      setSelectedIncidentIndexInternal((prev: number) => prev + 1);
    }
  }

  const decrementSelectedIndex = () => {
    if (selectedIncidentIndexInternal > 0) {
      setSelectedIncidentIndexInternal((prev: number) => prev - 1);
    }
  }

  const setSelectedIncidentIndex = (idx: number) => {
    if (idx < selectedIncidents.length) {
      setSelectedIncidentIndexInternal(idx);
    }
  }
  const addRiskToIncident = (risk: IncidentRiskDisplayType) => {
    setSubRiskDisplayTypesAssociatedWithIncident((oldVal) => {
      return [...oldVal,
        risk
      ]
    });
  };

  const removeRiskFromIncident = (subriskId: string) => {
    const copy = [...subRiskDisplayTypesAssociatedWithIncident];
    const indexToRemove = subRiskDisplayTypesAssociatedWithIncident.findIndex((srdt) => srdt.subRiskID === subriskId);
    copy.splice(indexToRemove, 1);
    setSubRiskDisplayTypesAssociatedWithIncident(copy);
  };



  const checkForErrors = () => {
    let hasErrors = false;
    const newErrors: string[] = [];
    if (triageStatus === "complete") {
      if (subRiskDisplayTypesAssociatedWithIncident.length === 0) {
        newErrors.push("Need to attribute at least one risk");
        setShowErrors(true);
        hasErrors = true;
      }
      if (!selectedStatus) {
        newErrors.push("Need to select a status");
        setShowErrors(true);
        hasErrors = true;
      }
      if (!selectedSourceType) {
        newErrors.push("Need to select a source type");
        setShowErrors(true);
        hasErrors = true;
      }
      if (!selectedSource) {
        newErrors.push("Need to select a source");
        setShowErrors(true);
        hasErrors = true;
      }
      if (subRiskDisplayTypesAssociatedWithIncident.filter((sr) => sr.isPrimary === 1).length > 1) {
        newErrors.push("Only one threat can be primary");
        setShowErrors(true);
        hasErrors = true;
      }

      if (subRiskDisplayTypesAssociatedWithIncident.length > 0 && !subRiskDisplayTypesAssociatedWithIncident.some((sr) => sr.isPrimary === 1)) {
        newErrors.push("One threat needs to be marked as primary");
        setShowErrors(true);
        hasErrors = true;
      }
    }
    setErrors(newErrors);
    return hasErrors;
  }

  return <EditIncidentContext.Provider value={{
    addRiskToIncident,
    analystNotesValue,
    batchMode,
    bookmarkedValue,
    checkForErrors,
    cityValue,
    countryValue,
    decrementSelectedIndex,
    dateReportedValue,
    displayIDValue,
    errors,
    errorLevel,
    incrementSelectedIndex,
    isDuplicateValue,
    locationValue,
    needsReviewLocal,
    notesValue,
    originalAnalystNotesValue,
    referenceRef,
    regionValue,
    removeRiskFromIncident,
    setRegionValue,
    selectedIncidentIndex: selectedIncidentIndexInternal,
    setAnalystNotesValue,
    setBookmarkedValue,
    setCityValue,
    setCountryValue,
    setDateReportedValue,
    setDisplayIDValue,
    setIsDuplicateValue,
    setLocationValue,
    setNeedsReviewLocal,
    setNotesValue,
    setOriginalAnalystNotesValue,
    setReferenceRef,
    setSelectedIncidentIndex,
    selectedStatus,
    setErrors,
    setSelectedStatus,
    selectedSource,
    setSelectedSource,
    selectedSourceType,
    setSelectedSourceType,
    stateProvinceValue,
    setStateProvinceValue,
    subRiskDisplayTypesAssociatedWithIncident,
    setSubRiskDisplayTypesAssociatedWithIncident,
    setShowErrors,
    setTitleValue,
    setTriageStatus,
    setTypeValue,
    showErrors,
    triageStatus,
    titleValue,
    typeValue,
    updateIncident
  }}>{props.children}
  </EditIncidentContext.Provider>

}