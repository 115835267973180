import React, { useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Button, Container } from "@amzn/awsui-components-react";
import { ModalContext } from "../useModal";

const ReadOnlyControlsTableInstructionsModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);
  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("readOnlyControlsTableInstructionsModal")}
    >
      <Container header={<h3 className="center">Welcome to the global physical security controls register</h3>}>
        <div className="modal-body">
          <p>The <strong>global physical security controls register</strong> is a collection of security controls deployed across Amazon Corporate Security (ACS) sites globally.
            The controls are categorized by their respective function and titled using their common name.
            To get started, search for the control and select the control name to display the control card.</p>

          <strong>The Control Card:</strong>
          <ul className="no-list-style">
            <li><strong>Control type:</strong> Control function</li>
            <li><strong>Control name:</strong> Name of control</li>
            <li><strong>General description:</strong> Description of the control and information about how the control contributes to security depth</li>
            <li><strong>Owner:</strong> The functional unit responsible for the control</li>
            <li><strong>Protection planning:</strong> Information about the control’s contribution to security depth</li>
            <li><strong>Performance criteria:</strong> Describes a controls effectiveness criteria</li>
            <li><strong>Status:</strong> Whether or not the control is active</li>
          </ul>

          <strong>Security Controls Management Plan (SCMP)</strong>
          <p>The SCMP provides basic information about security controls, how they are used, and how they contribute to the protection strategy (security depth). Included is information about common security threat management 
            frameworks and concepts to aid in the design and implementation of the protection plan.</p>

          <p>For questions and comments please reach out to <Button href="mailto:SecEvals-Global@amazon.com" variant="inline-link">SecEvals-Global@amazon.com</Button></p>
        </div>
      </Container>
    </Modal >
  );
};

export default ReadOnlyControlsTableInstructionsModal;
