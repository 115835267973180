import React, { useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { Header, Table } from "@amzn/awsui-components-react";

import { ModalContext } from "../../useModal";
import { DefenseInDepthControlType } from "../../../types";
import { SiteControlsContext } from "../useSiteControls";

const ReadOnlyControlSetForRiskModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);
  const { controlsInUseAtCurrentSite, nDelayControlsInUse, nDeterControlsInUse, nDetectControlsInUse, nRecoverControlsInUse, nRespondControlsInUse, selectedRiskName } = useContext(SiteControlsContext);
  const { items: itemsFromUseCollection, collectionProps } = useCollection(controlsInUseAtCurrentSite, {
    sorting: {},
    selection: {},
  });

  const yesOrPartialCount = controlsInUseAtCurrentSite.filter((e: DefenseInDepthControlType) => e.isUsedAtSite).length;
  const percentage = Math.round((yesOrPartialCount / controlsInUseAtCurrentSite.length) * 100);


  return (
    <Modal
      header={<Header
        description="Control utilization [Active (black) / Available (gray)] "
      >Mitigating controls for <strong>{selectedRiskName}</strong> - {percentage}% </Header>}
      onDismiss={() => hideModals()}
      size="max"
      visible={isShowing("ReadOnlyControlSetForRiskModal")}
    >
      <Table
        {...collectionProps}
        columnDefinitions={[
          {
            id: "controlName",
            header: "Control",
            cell: (e: DefenseInDepthControlType) => (<span className={e.isUsedAtSite ? "" : "muted"}>{e.controlName}</span>),
            sortingField: "controlName",
            isRowHeader: true,
            width: 100,
          },
          {
            id: "criticality",
            header: "Criticality",
            cell: (e: DefenseInDepthControlType) => e.criticality,
            sortingField: "criticality",
            isRowHeader: true,
            width: 100,
          },{
            id: "deter",
            header: <span>Deter ({nDeterControlsInUse})</span>,
            cell: (e: DefenseInDepthControlType) => (<span>{e.deter === 1 ? "x" : ""}</span>),
            sortingField: "deter",
            isRowHeader: true,
            width: 60,
          },
          {
            id: "detect",
            header: <span>Detect ({nDetectControlsInUse})</span>,
            cell: (e: DefenseInDepthControlType) => (<span>{e.detect === 1 ? "x" : ""}</span>),
            sortingField: "detect",
            isRowHeader: true,
            width: 60,
          },
          {
            id: "delay",
            header: <span>Delay ({nDelayControlsInUse})</span>,
            cell: (e: DefenseInDepthControlType) => (<span>{e.delay === 1 ? "x" : ""}</span>),
            sortingField: "delay",
            isRowHeader: true,
            width: 60,
          },
          {
            id: "respond",
            header:  <span>Respond ({nRespondControlsInUse})</span>,
            cell: (e: DefenseInDepthControlType) => (<span>{e.respond === 1 ? "x" : ""}</span>),
            sortingField: "respond",
            isRowHeader: true,
            width: 60,
          },
          {
            id: "recover",
            header: <span>Recover ({nRecoverControlsInUse})</span>,
            cell: (e: DefenseInDepthControlType) => (<span>{e.recover === 1 ? "x" : ""}</span>),
            sortingField: "recover",
            isRowHeader: true,
            width: 60,
          },
        ]}
        enableKeyboardNavigation
        stripedRows
        items={itemsFromUseCollection}
        loadingText="Loading controls"
      />
    </Modal >
  );
};

export default ReadOnlyControlSetForRiskModal;
