import React, { useContext, useEffect, useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Button from "@amzn/awsui-components-react/polaris/button";
import { useCollection } from "@amzn/awsui-collection-hooks";
import EmptyState from "../../EmptyState";
import { SelectedSiteContext } from "src/components/useSelectedSite";
import { SiteEventDisplayType } from "../../../types";
import { SiteEventsContext } from "../useEvents";
import { Pagination } from "@amzn/awsui-components-react";
import { paginationLabels } from "../../../constants";

const EventsTable = () => {
  const { eventsForCurrentSite, isLoading } = useContext(SiteEventsContext);
  const { site } = useContext(SelectedSiteContext);
  const [localLoadingText, setLocalLoadingText] = useState("loading")

  useEffect(() => {

    setLocalLoadingText(isLoading ? "loading" : "")
  }, [isLoading]);

  const {
    items: itemsFromUseCollection,
    actions,
    collectionProps,
    paginationProps,
  } = useCollection(eventsForCurrentSite, {
    filtering: {
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: 10 },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'startDate',
        },
        isDescending: false
      }},
    selection: {},
  });
  return (isLoading ? <span></span> : <Table
    header={<strong>Events</strong>}
    {...collectionProps}

    ariaLabels={{
      selectionGroupLabel: "Items selection",
      allItemsSelectionLabel: ({ selectedItems }) =>
        `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
        } selected`,
    }}
    columnDefinitions={[
      {
        id: "startDate",
        header: "Start date",
        cell: (e: SiteEventDisplayType) => e.startDate,
        sortingField: "startDate",
        isRowHeader: true,
      },
      {
        id: "endDate",
        header: "End date",
        cell: (e: SiteEventDisplayType) => e.endDate,
        sortingField: "endDate",
        isRowHeader: true,
      },
      {
        id: "eventName",
        header: "Name",
        cell: (e: SiteEventDisplayType) => e.eventName,
        sortingField: "eventName",
        isRowHeader: true,
      },
      {
        id: "eventSpace",
        header: "Location",
        cell: (e: SiteEventDisplayType) => e.eventSpace,
        sortingField: "eventSpace",
        isRowHeader: true,
      },
    ]}
    empty={!isLoading && `No events found for ${site}`}
    loading={isLoading}
    items={itemsFromUseCollection}
    loadingText={localLoadingText}
    pagination={
      <Pagination {...paginationProps} ariaLabels={paginationLabels} />
    }
    wrapLines
  />);
}

export default EventsTable;