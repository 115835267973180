import React, { useContext } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from "@amzn/awsui-collection-hooks";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { paginationLabels } from "../../../../constants";
import { ContentDisplayItem, SiteLogEntryDBType } from "../../../../types";
import { SelectedSiteContext } from "../../../useSelectedSite";
import { SiteLogContext } from "../../useSiteLog";
import EmptyState from "../../../EmptyState";
import { useLocalStorage } from "src/utils/use-local-storage";

const SiteActivityLog = () => {
  const { state } = useContext(SiteLogContext);
  const { selectedEntity } = useContext(SelectedSiteContext);

  function getMatchesCountText(count: number | undefined) {
    return count === 1 ? `1 match` : `${count} matches`;
  }

  const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
      {
        id: "table_that_changed",
        visible: true,
      },
      {
        id: "column_that_changed",
        visible: true,
      },
      {
        id: "new_value",
        visible: true,
      },
      {
        id: "changed_by",
        visible: true,
      },
      {
        id: "date_reported",
        visible: true,
      },
      
    ]
  }

  const [preferences, setPreferences] = useLocalStorage<{
    pageSize: number,
    contentDisplay: readonly ContentDisplayItem[],
  }>("site_activity-log-table-preferences", DEFAULT_PREFERENCES);

  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(state.logEntries, {
    filtering: {
      empty: (
        <EmptyState
          title="No Logs Found"
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
     
    },
    pagination: { pageSize: preferences.pageSize },
    selection: {},
  });

  return (<div className="site-tab-content">
    <Table
      {...collectionProps}
      columnDefinitions={[
        {
          id: "table_that_changed",
          header: "Table that changed",
          cell: (e: SiteLogEntryDBType) => e.table_that_changed,
          isRowHeader: true,
        },
        {
          id: "column_that_changed",
          header: "Parameter that changed",
          cell: (e: SiteLogEntryDBType) => e.column_that_changed,
          isRowHeader: true,
        },
        {
          id: "new_value",
          header: "New value",
          cell: (e: SiteLogEntryDBType) => e.new_value,
          isRowHeader: true,
        },
        {
          id: "changed_by",
          header: "Changed by",
          cell: (e: SiteLogEntryDBType) => e.changed_by,
          isRowHeader: true,
        },
        {
          id: "date_reported",
          header: "Date changed",
          cell: (e: SiteLogEntryDBType) => e.changed_on.toString(),
          isRowHeader: true,
        },
      ]}
      columnDisplay={preferences.contentDisplay}
      enableKeyboardNavigation
      items={itemsFromUseCollection}
      loadingText="Loading logs"
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No entries</b>
          </SpaceBetween>
        </Box>
      }
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringPlaceholder="Search logs..."
          filteringAriaLabel="Filter"
        />
      }
      header={
        <Header>{selectedEntity.site} activity log</Header>
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: preferences.pageSize,
            contentDisplay: preferences.contentDisplay,
          }}
          onConfirm={({ detail }) => {
            const { pageSize, contentDisplay } = detail;
            if (typeof pageSize === "number" && contentDisplay) {
              setPreferences({ pageSize, contentDisplay });
            }
          }}
          pageSizePreference={{
            title: "Select page size",
            options: [
              { value: 10, label: "10 log entries" },
              { value: 20, label: "20 log entries" },
            ],
          }}
          contentDisplayPreference={{
            options: [
              {
                id: "table_that_changed",
                label: "Table that changed",
                alwaysVisible: true,
              },
              {
                id: "column_that_changed",
                label: "Column that changed",
                alwaysVisible: true,
              },
              {
                id: "new_value",
                label: "New value",
                alwaysVisible: true,
              },
              { id: "changed_by", 
                label: "Changed by", 
                alwaysVisible: true 
              },
              {
                id: "date_reported",
                label: "Date reported",
                alwaysVisible: true,
              },
            ],
          }}
        />
      }
      wrapLines={true}
    />
  </div>);
}

export default SiteActivityLog;