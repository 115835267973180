import { useContext, useEffect, useState } from "react";
import {Navigate} from "react-router-dom";
import RiskRow from "./RiskRow";
import { RiskContext } from "./RiskProvider";
import { RoleContext } from "./RoleProvider";
import {ModalContext} from "./useModal";
import { Button } from "@amzn/awsui-components-react";
import EmptyState from "./EmptyState";

const RiskTable = () => {
  const { state, dispatch } = useContext(RiskContext);
  const { showModal } = useContext(ModalContext);
  const { roleFetchComplete, userIsRiskAdmin} = useContext(RoleContext);

  const riskRows = state.risks.map((risk) => {
    const subRisksRows = state.subRisks.filter((sr) => {
      return sr.risk_id === risk.risk_number;
    });
    return (
      <RiskRow
        key={risk.risk_number + "_key"}
        risk={risk}
        subRisks={subRisksRows}
        
      />
    );
  });

  if(!userIsRiskAdmin && roleFetchComplete){
    return <Navigate to="/" />;
  }

  if(!roleFetchComplete) {
    return <EmptyState title="Loading risks..." subtitle="" action="" />
  }

  return (
    <div>
      <div className="new-risk-category-container"><Button onClick={(evt) => {
        dispatch({Type: "SET_SELECTED_RISK_CATEGORY_TO_EMPTY", Cargo: 0});
        showModal("editRisk")
      }}>New threat category</Button></div>
      {riskRows}
    </div>
  );
};

export default RiskTable;
