import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { SelectedSiteContext } from "../useSelectedSite";
import { SiteEventDBType, SiteEventDisplayType, SiteEventsDictionary } from "src/types";
import useSecuredFetch from "../useSecuredFetch";
import { getBaseUrl, getMonthAndDay } from "src/utils";

export type EventsContextType = {
    eventsForCurrentSite: SiteEventDisplayType[],
    isLoading: boolean
}

export const SiteEventsContext = createContext<EventsContextType>({ eventsForCurrentSite: [], isLoading: false });

export const SiteEventsProvider = (props: { children: JSX.Element }) => {
    const { site, selectedEntity } = useContext(SelectedSiteContext);
    const secureFetch = useSecuredFetch();

    const [eventsForAllSites, setEventsForAllSites] = useState<SiteEventsDictionary>({});
    const [eventsForCurrentSite, setEventsForCurrentSite] = useState<SiteEventDisplayType[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchFxn = async () => {
            setEventsForCurrentSite([]);
            if (site !== "") {
                if (eventsForAllSites[site]?.wasFetched) {
                    setIsLoading(false);
                    setEventsForCurrentSite(eventsForAllSites[site].events);
                } else {
                    setIsLoading(true)
                    const newDictionary = { ...eventsForAllSites };
                    const result = await secureFetch(`${getBaseUrl()}/events?entity=${selectedEntity.entityId}`, 'GET');
                    const json = await result.json();
                    const eventsToDisplay = json.events.map((a: SiteEventDBType) => {
                        return {
                            startDate: getMonthAndDay(a.start_date),
                            eventName: a.event_name,
                            endDate: getMonthAndDay(a.end_date),
                            eventSpace: a.event_space
                        }
                    });
                    newDictionary[site] = {
                        events: eventsToDisplay,
                        wasFetched: true
                    };
                    setEventsForAllSites(newDictionary);
                    setEventsForCurrentSite(eventsToDisplay);
                    setIsLoading(false);
                }
            }
        }
        fetchFxn();

    }, [selectedEntity.site]);

    return (<SiteEventsContext.Provider value={{ eventsForCurrentSite, isLoading }}>
        {props.children}
    </SiteEventsContext.Provider>)
}