import React, { useContext } from "react";
import { Box, Button, ColumnLayout, Container, Header, Pagination, Popover, Table, TextFilter } from "@amzn/awsui-components-react/polaris";
import { useCollection } from "@amzn/awsui-collection-hooks";

import { HealthCheckRowType } from "src/types";
import EmptyState from "../EmptyState";
import { RED, YELLOW, ORANGE, LIME, GREEN, paginationLabels } from "../../constants";
import { ControlSetContext } from "../ControlSetProvider";
import { DefinitionsContext } from "../DefinitionsProvider";
import { SelectedSiteContext } from "../useSelectedSite";
import { RiskContext } from "../RiskProvider";
import { ModalContext } from "../useModal";
import ReadOnlyControlSetForHealthCheck from "./SiteLandingPage/ReadOnlyControlSetForHealthCheck";
import UnusedControlSetForHealthCheck from "./SiteLandingPage/UnusedControlSetForHealthCheck";


const HealthCheck = () => {
  const { healthCheckResults, selectedEntity } = useContext(SelectedSiteContext);
  const { dispatch, getFirstSubRiskId, getFullCategoryName } = useContext(RiskContext);
  const {getControlSet} = useContext(ControlSetContext);
  const {showModal} = useContext(ModalContext);

  function getMatchesCountText(count: number | undefined) {
    return count === 1 ? `1 match` : `${count} matches`;
  }

  const { LikelihoodRatingDefinitions } = useContext(DefinitionsContext);

  const getBackgroundColorForLikelihood = (rating: number): string => {
    if (rating === 5) {
      return RED;
    }
    if (rating === 4) {
      return ORANGE;
    }
    if (rating === 3) {
      return YELLOW;
    }
    if (rating === 2) {
      return LIME;
    }
    if (rating === 1) {
      return GREEN;
    }
    return "inherit";
  }

  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(healthCheckResults, {
    filtering: {
      empty: (
        <EmptyState
          title="No Items Found"
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: 25 },
    sorting: {},
    selection: {},
  });

  const getCellStyle = (risk: HealthCheckRowType) => {
    if (risk.risk_name === "") return { display: "none" };
    const firstLetter = getFirstSubRiskId(parseInt(risk.risk_name?.[0]))

    if (risk.risk_name[1]?.toLocaleLowerCase() === firstLetter) {
      return { display: "inline" };
    } else {
      return { display: "none" };
    }
  };

  return (<>
    <ReadOnlyControlSetForHealthCheck />
    <UnusedControlSetForHealthCheck />
    <Table
      {...collectionProps}
      columnDefinitions={[
        {
          id: "risk-category",
          header: "Threat category",
          cell: (e: HealthCheckRowType) => {
            return (<span style={getCellStyle(e)}>{getFullCategoryName(e.risk_category_name)}</span>);
          },
          isRowHeader: true,
          width: 330,
        },
        {
          id: "risk",
          header: "Threat",
          cell: (e: HealthCheckRowType) => e.risk_name,
          width: 400,
        },
        {
          id: "controls-number",
          header: <><div>Protection strategy</div><div>(active/available)</div></>,
          cell: (e: HealthCheckRowType) => <Button variant="link" onClick={(_e) => {
            getControlSet(e.riskId);
            dispatch({ Type: "SET_SELECTED_SUBRISK", Cargo: { acs_risk_dim_sk: e.riskId } })
            showModal("ReadOnlyControlSetForRiskModal");
          }}>{e.controlSetFraction}</Button>,
          width: 225,
        },
        {
          id: "additional-controls",
          header: "Additional controls",
          cell: (e: HealthCheckRowType) => <Button variant="link" onClick={(_e) => {
            getControlSet(e.riskId);
            dispatch({ Type: "SET_SELECTED_SUBRISK", Cargo: { acs_risk_dim_sk: e.riskId } })
            showModal("UnusedControlSetForRiskModal");
          }}>Show</Button>,
          width: 225,
        },
        {
          id: "control-likelihood",
          header: <Popover
            fixedWidth
            header="CBL Definitions"
            size="large"
            content={
              <ColumnLayout>
                <Container><Box variant="awsui-key-label">Rating</Box><Box variant="awsui-key-label">Definition</Box></Container>
                <Container>
                  <Box>1 - <b>Rare</b></Box>
                  <Box>All of the controls are present with no effectiveness issues.</Box>
                </Container>
                <Container>
                  <Box>2 - <b>Unlikely</b></Box>
                  <Box>The majority of the controls are present, with few effectiveness issues.</Box>
                </Container>
                <Container>
                  <Box>3 - <b>Possible</b></Box>
                  <Box>Some controls associated with the threat are not present or not performing effectively.</Box>
                </Container>
                <Container>
                  <Box>4 - <b>Likely</b></Box>
                  <Box>The majority of the controls associated with the threat are not present and/or not performing effectively.</Box>
                </Container>
                <Container>
                  <Box>5 - <b>Almost Certain</b></Box>
                  <Box>Critical and supplementary controls associated with the threat are not present and/or not performing effectively.</Box>
                </Container>
              </ColumnLayout>
            }
          >
            <strong>Control-based likelihood</strong>
          </Popover>,
          cell: (e: HealthCheckRowType) => (<div className="table-cell-background" style={{ background: getBackgroundColorForLikelihood(parseInt(e.cbr_likelihood[0])) }}>{e.cbr_likelihood}</div>),
        },
        {
          id: "incident-based-likelihood",
          header: <Popover
            fixedWidth
            header="IBL Definitions"
            size="large"
            content={
              <ColumnLayout>
                <Container><Box variant="awsui-key-label">Rating</Box><Box variant="awsui-key-label">Definition</Box></Container>
                <Container>
                  <Box>1 - <b>Rare</b></Box>
                  <Box>Could occur at least once every two to three years.</Box></Container>
                <Container>
                  <Box>2 - <b>Unlikely</b></Box>
                  <Box>Could occur at least once per year.</Box></Container>
                <Container>
                  <Box>3 - <b>Possible</b></Box>
                  <Box>Could occur at least once per quarter.</Box></Container>
                <Container>
                  <Box>4 - <b>Likely</b></Box>
                  <Box>Could occur at least once per month.</Box></Container>
                <Container>
                  <Box>5 - <b>Almost Certain</b></Box>
                  <Box>Could occur at least once or more a week.</Box></Container>
              </ColumnLayout>
            }
          >
            <strong>
            Incident-based likelihood
            </strong>
          </Popover>,
          cell: (e: HealthCheckRowType) => (<div className="table-cell-background" style={{ background: getBackgroundColorForLikelihood(parseInt(e.ib_likelihood[0])) }}>{e.ib_likelihood}</div>),
        },
        {
          id: "likelihood-rating",
          header: <Popover
            fixedWidth
            header="Threat Likelihood Definitions"
            size="large"
            content={
              <ColumnLayout>
                <Container><Box variant="awsui-key-label">Rating</Box><Box variant="awsui-key-label">Definition</Box></Container>
                <Container>
                  <Box>1 - <b>Rare</b></Box>
                  <Box>Could occur at least once every two to three years</Box></Container>
                <Container>
                  <Box>2 - <b>Unlikely</b></Box>
                  <Box>Could occur at least once per year</Box></Container>
                <Container>
                  <Box>3 - <b>Possible</b></Box>
                  <Box>Could occur at least once per quarter</Box></Container>
                <Container>
                  <Box>4 - <b>Likely</b></Box>
                  <Box>Could occur at least once per month</Box></Container>
                <Container>
                  <Box>5 - <b>Almost Certain</b></Box>
                  <Box>Could occur at least once or more a week
                  </Box></Container>
              </ColumnLayout>
            }
          >
            <strong>Threat likelihood</strong>
          </Popover>,
          cell: (e: HealthCheckRowType) => (<div className="table-cell-background" style={{ background: getBackgroundColorForLikelihood(Math.round(e.overall_likelihood)) }}>{Math.round(e.overall_likelihood)} - {LikelihoodRatingDefinitions[Math.round(e.overall_likelihood)]}</div>)
        },
      ]}
      enableKeyboardNavigation
      items={itemsFromUseCollection}
      loadingText="Loading healthcheck data"
      wrapLines
      trackBy="risk_name"
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringPlaceholder="Find a threat or threat category"
          filteringAriaLabel="Filter threats"
        />
      }
      header={
        <Header>
          {selectedEntity.site} health check
        </Header>
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
    />
  </>);
}

export default HealthCheck;