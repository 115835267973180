import React, { createContext, useContext, useState, useEffect } from "react";
import { emptyLocation, EmptyManager } from "../constants";
import { RoleContext } from "./RoleProvider";
import { ModalContext } from "./useModal";
import { SelectedSiteContext } from "./useSelectedSite";
import useSecuredFetch from "./useSecuredFetch";
import { EntityType, SecurityManagerType, WorstStatusType } from "src/types";
import { getBaseUrl } from "../utils"

const initialState = {
    aors: [""], //the aors for whatever region is selected
    allManagers: [EmptyManager],
    countries: [""], //countries of whatever aor is selected
    entities: [emptyLocation],
    error: "",
    getMgrFullName: (_alias: string) => "",

    managers: [EmptyManager], //the managers for whatever region is selected
    isLoading: true,

    refreshEntitiesAndManagers: () => { },
    selectedRegion: "",
    setSelectedRegion: (_region: string) => { },
    setSelectedAOR: (_aor: string) => { },
    selectedAOR: "",

    siteCountsByRegion: [{ region: "AMER", site_count: 0 }, { region: "APAC", site_count: 0 }, { region: "EMEA", site_count: 0 }],
    updateEntitiesWithEditedEntity: (_updatedSite: EntityType) => { }
};

export type EntityAndManagerContextType = typeof initialState;

export const EntityAndManagerContext = createContext(initialState);

/**
 * 
 */
export const EntityAndManagerProvider = (props: { children: JSX.Element }) => {
    const [entities, setEntities] = useState<EntityType[]>([]);
    const { hideModals } = useContext(ModalContext);
    const { selectedEntity, setSelectedLocation, refreshCounterForSelectedSite } = useContext(SelectedSiteContext);
    const secureFetch = useSecuredFetch();

    const regionsEndpoint = `${getBaseUrl()}/regionsAorsCountries`;
    const { fakeLoggedInUser, loggedInUser, userIsRiskAdmin, roleFetchComplete, token } = useContext(RoleContext);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const [aorsByRegion, setAorsByRegion] = useState({
        EMEA: new Set<string>(),
        AMER: new Set<string>(),
        APAC: new Set<string>()
    });
    const [aors, setAors] = useState([""]);
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedAOR, setSelectedAOR] = useState("");
    const [countries, setCountries] = useState([""]);
    const [allManagers, setAllManagers] = useState<SecurityManagerType[]>([EmptyManager]);
    //the list of managers for the currently-selected region
    const [managers, setManagers] = useState<SecurityManagerType[]>([]);
    const [needsRefresh, setNeedsRefresh] = useState(false);
    const [siteCountsByRegion, setSiteCountsByRegion] = useState([{ region: "AMER", site_count: 0 }, { region: "APAC", site_count: 0 }, { region: "EMEA", site_count: 0 }])

    interface StringDictionary {
        [key: string]: string[];
    }

    const [countriesByAOR, setCountriesByAOR] = useState<StringDictionary>({});

    const fetchEntities = async () => {
        if (!roleFetchComplete) {
            return;
        }
        let baseEntitiesUrl = `${getBaseUrl()}/entities`
        let entitiesUrl = baseEntitiesUrl;
        if (userIsRiskAdmin) {
            entitiesUrl = fakeLoggedInUser ? `${baseEntitiesUrl}?alias=${fakeLoggedInUser}` : baseEntitiesUrl;
        } else {
            entitiesUrl = `${baseEntitiesUrl}?alias=${loggedInUser}`;
        }
        const res = await secureFetch(entitiesUrl, "GET");
        if (res.status !== 200) {
            setError("Could not load entities");
            setIsLoading(false);
            return;
        }
        const json = await res.json();
        setEntities(json.entities);
        if (selectedEntity.entityId !== emptyLocation.entityId) {
            const selectedSite = json.entities.find((a: EntityType) => a.site === selectedEntity.site);
            setSelectedLocation(selectedSite);
        }

        setSiteCountsByRegion(json.siteCountByRegion);

    }

    const fetchWorstDefectStatusForEachSite = async () => {
        if (!roleFetchComplete) {
            return;
        }
        let worstDefectsUrl = `${getBaseUrl()}/worstDefectStatusBySite`

        const res = await secureFetch(worstDefectsUrl, "GET");
        if (res.status !== 200) {
            setError("Could not load worst defects");
            setIsLoading(false);
            return;
        }
        const freshenedEntities = [...entities];
        const json = await res.json();
        const siteDefectColors = json.defects;
        for (let i = 0; i < freshenedEntities.length; i++) {
            const site = freshenedEntities[i].site;
            const defectColor = siteDefectColors.find((a: WorstStatusType) => a.site_code === site);
            freshenedEntities[i].defect_status_color = defectColor?.max_color || "";
        }

        setEntities(freshenedEntities);
    }

    const fetchManagers = async () => {
        const managersUrl = `${getBaseUrl()}/managers`
        const res = await secureFetch(managersUrl, 'GET');
        if (res.status !== 200) {
            setError("Could not load entities");
            setIsLoading(false);
            return;
        }
        const json = await res.json();
        setAllManagers(json.managers);

    }

    const updateEntitiesWithEditedEntity = () => {
        let newEntities = [...entities];
        // If we're updating an existing item, just remove it and then add the updated one
        const oldCopyOfSelectedEntity = newEntities.filter((ent) => ent.site === selectedEntity.site)[0];
        const indexToRemove = newEntities.indexOf(oldCopyOfSelectedEntity);
        newEntities.splice(indexToRemove, 1);
        newEntities.push(selectedEntity);
        setEntities(newEntities);
    }

    //useSelectedSite hook will tell us when it has refetched the entities after editing a location
    useEffect(updateEntitiesWithEditedEntity, [refreshCounterForSelectedSite])

    const fetchRegionsAorsAndCountries = async () => {
        const res = await secureFetch(regionsEndpoint, 'GET');
        if (res.status !== 200) {
            setError("Could not load regions and errors");
            setIsLoading(false);
            return;
        }
        const json = await res.json();
        const { data, aorsAndCountries } = json;
        const newAORsByRegion = {
            EMEA: new Set<string>(),
            AMER: new Set<string>(),
            APAC: new Set<string>(),
        };

        for (let i = 0; i < data.length; i++) {
            switch (data[i].region) {
                case "APAC":
                    newAORsByRegion.APAC.add(data[i].aor);
                    break;
                case "AMER":
                    newAORsByRegion.AMER.add(data[i].aor);
                    break;
                case "EMEA":
                    newAORsByRegion.EMEA.add(data[i].aor);
                    break;
            }
        }

        setAorsByRegion(newAORsByRegion);
        const newCountriesByAOR: Record<string, string[]> = {};
        for (let i = 0; i < aorsAndCountries.length; i++) {
            if (!newCountriesByAOR[aorsAndCountries[i].aor]) {
                newCountriesByAOR[aorsAndCountries[i].aor] = [];
            }
            newCountriesByAOR[aorsAndCountries[i].aor].push(aorsAndCountries[i].country)
        }
        setCountriesByAOR(newCountriesByAOR);

    }

    const fetchEverything = async () => {
        if (roleFetchComplete) {
            await Promise.all([fetchEntities(), fetchRegionsAorsAndCountries(), fetchManagers()]);
            setIsLoading(false);
            setNeedsRefresh(false);
        }
    }

    useEffect(() => {
        fetchEverything();
    }, [roleFetchComplete, needsRefresh, fakeLoggedInUser]);

    useEffect(() => {
        if (roleFetchComplete && entities.length > 1) {
            fetchWorstDefectStatusForEachSite();
        }

    }, [roleFetchComplete, needsRefresh, fakeLoggedInUser, entities.length]);

    useEffect(() => {
        switch (selectedRegion) {
            case "APAC":
                setAors(Array.from(aorsByRegion.APAC));
                setManagers(allManagers.filter((mgr) => mgr.region === "APAC"));
                break;
            case "AMER":
                setAors(Array.from(aorsByRegion.AMER));
                setManagers(allManagers.filter((mgr) => mgr.region === "AMER"));
                break;
            case "EMEA":
                setAors(Array.from(aorsByRegion.EMEA));
                setManagers(allManagers.filter((mgr) => mgr.region === "EMEA"));
                break;
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (countriesByAOR[selectedAOR]) {
            setCountries(countriesByAOR[selectedAOR]);
        }
    }, [selectedAOR]);

    useEffect(() => {
        if (countriesByAOR[selectedEntity.aor]) {
            setCountries(countriesByAOR[selectedEntity.aor]);
        }
    }, [selectedEntity.site]);

    useEffect(() => {
        setSelectedRegion(selectedEntity.region);
        switch (selectedEntity.region) {
            case "APAC":
                setAors(Array.from(aorsByRegion.APAC));
                break;
            case "AMER":
                setAors(Array.from(aorsByRegion.AMER));
                break;
            case "EMEA":
                setAors(Array.from(aorsByRegion.EMEA));
                break;
        }
    }, [selectedEntity.site]);

    const getMgrFullName = (alias: string) => {
        const mgr = allManagers.find((mgr) => mgr.alias === alias);
        if (mgr) {
            return `${mgr.full_name}`;
        }
        return "";
    }

    const refreshEntitiesAndManagers = () => {
        setNeedsRefresh(true);
    }

    return (<EntityAndManagerContext.Provider value={{
        aors, allManagers, countries, error, entities, getMgrFullName, isLoading, managers, refreshEntitiesAndManagers,
        selectedRegion, setSelectedRegion, selectedAOR, setSelectedAOR, siteCountsByRegion, updateEntitiesWithEditedEntity
    }}>
        {props.children}
    </EntityAndManagerContext.Provider>)
}