import React, { useContext, useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { ModalContext } from "./useModal";
import { RiskContext } from "./RiskProvider";
import { ControlSetContext } from "./ControlSetProvider";
import { ControlDataContext } from "./ControlsProvider";
import { Box, Button, ColumnLayout, Flashbar, Grid, Select, SpaceBetween, Table } from "@amzn/awsui-components-react";
import { RiskControlCriticalityDisplayType } from "../types";
import { DefinitionsContext } from "./DefinitionsProvider";
import { CriticalityOptions, DefaultOption } from "../constants";

const ControlSetEditorForRiskModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);
  const { state: riskState } = useContext(RiskContext);
  const { addRiskToControlSet, dispatch, state: controlSetState, removeRiskFromControlSet } = useContext(ControlSetContext);
  const { CriticalityDefinitions } = useContext(DefinitionsContext);
  const risk = riskState.selectedSubRisk;
  const { getControlInfo, controlCategories, allControls } = useContext(ControlDataContext)
  const controlCriticalities = controlSetState.controlCriticalitiesByRisk[risk.acs_risk_dim_sk];
  const [controlCategoryOptions, setControlCategoryOptions] = useState([DefaultOption]);
  const [controlOptions, setControlOptions] = useState([DefaultOption]);
  const [criticalityOptions, setCriticalityOptions] = useState([DefaultOption]);
  const [enableSubmitButton, setEnableSubmitButton] = useState(false)

  useEffect(() => {
    if (controlCategories.length > 1) {
      let controlCategoryOpts = (controlCategories?.map((cc) => ({ label: cc.name, value: cc.id.toString() })));
      controlCategoryOpts.unshift({ label: "Select category", value: "-1" });
      setControlCategoryOptions(controlCategoryOpts);
      setSelectedControlCategory(controlCategoryOpts[0]);

      setCriticalityOptions(CriticalityOptions);
      setSelectedCriticality(CriticalityOptions[0]);
    }
  }, [controlCategories.length]);

  const [isAddingNewControl, setIsAddingNewControl] = useState(false);
  const [selectedControlCategory, setSelectedControlCategory] = useState(controlCategoryOptions[0]);


  useEffect(() => {
    let filteredControls = allControls.filter((c) => {
      return c.category_type_id.toString() == selectedControlCategory.value
        && !controlCriticalities.some((cc) => cc.control_id == c.id.toString());
    })
    let controlOpts = (filteredControls?.map((fc) => ({ label: fc.control_name, value: fc.id.toString() })));
    controlOpts.unshift({ label: "Select control", value: "-1" });
    setControlOptions(controlOpts);
    setSelectedControl(controlOpts[0]);
    setSelectedCriticality(CriticalityOptions[0])

  }, [selectedControlCategory.value]);

  const [selectedControl, setSelectedControl] = useState(controlOptions[0]);
  const [selectedCriticality, setSelectedCriticality] = useState(CriticalityOptions[0]);

  const controlInfoRows = controlCriticalities?.map((cc) => {
    const extraControlInfo = getControlInfo(cc.control_id);
    return {
      ...cc,
      control_name: extraControlInfo.controlName,
      criticality_name: `${cc.criticality} - ${CriticalityDefinitions[cc.criticality].name}`,
      description: CriticalityDefinitions[cc.criticality].description,
      owner_name: extraControlInfo.owner,
    }
  }) || [{
    control_id: -1,
    control_name: "",
    criticality_name: "",
    description: "",
    owner_name: ""
  }];

  const { items: itemsFromUseCollection, collectionProps } = useCollection(controlInfoRows, {
    sorting: {},
    selection: {},
  });

  const resetForm = () => {
    setSelectedControlCategory(controlCategoryOptions[0]);
    setSelectedControl(DefaultOption)
    setSelectedCriticality(criticalityOptions[0])
  }

  return (
    <Modal
      footer={<Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
        <div>
          <Box>
            {controlSetState.successMessage && <Flashbar
              items={[
                {
                  content: controlSetState.successMessage,
                  dismissible: true,
                  onDismiss: (_evt) => dispatch({ Type: "HIDE_MESSAGES", Cargo: "" }),
                  id: "message_1",
                  type: "success"
                },
              ]}
            />
            }
            {controlSetState.error && <Flashbar
              items={[
                {
                  content: controlSetState.error,
                  dismissible: true,
                  onDismiss: (_evt) => dispatch({ Type: "HIDE_MESSAGES", Cargo: "" }),
                  id: "message_1",
                  type: "error"
                },
              ]}
            />
            }</Box>
        </div>

        <Box>
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => {
              resetForm();
              hideModals();
            }}>
              Cancel
            </Button>
            <Button disabled={!enableSubmitButton} onClick={async () => {
              const success = await addRiskToControlSet(parseInt(selectedControl.value),
                risk.acs_risk_dim_sk,
                parseInt(selectedCriticality.value));
              if (success) {
                resetForm();
                setEnableSubmitButton(false);
                setIsAddingNewControl(false);
              }
            }}>Submit</Button>
          </SpaceBetween>
        </Box>
      </Grid>
      }
      header={`Control set for ${riskState.selectedSubRisk.sub_risk_name}`}
      onDismiss={() => {
        hideModals();
        resetForm();
      }}
      size="max"
      visible={isShowing("controlSetEditorForRiskModal")}
    >
      <div className="control-details-modal">

        <Table
          {...collectionProps}
          columnDefinitions={[
            {
              id: "control_name",
              header: <span data-testid="category-type-header">Control</span>,
              cell: (e: RiskControlCriticalityDisplayType) => (<span>{e.control_name}</span>),
              sortingField: "control_name",
              isRowHeader: true,
              width: 200,
            },
            {
              id: "owner_name",
              header: <span>Owner</span>,
              cell: (e: RiskControlCriticalityDisplayType) => (<span>{e.owner_name}</span>),
              sortingField: "owner_name",
              isRowHeader: true,
              width: 150,
            },
            {
              id: "criticality_name",
              header: <span>Criticality</span>,
              cell: (e: RiskControlCriticalityDisplayType) => (<span>{e.criticality_name}</span>),
              sortingField: "criticality_name",
              isRowHeader: true,
              width: 200,
            },
            {
              id: "description",
              header: <span>Description</span>,
              cell: (e: RiskControlCriticalityDisplayType) => (<span>{e.description}</span>),
              sortingField: "description",
              isRowHeader: true,
              width: 300,
              maxWidth: 310
            },
            {
              id: "remove",
              header: <span>Remove</span>,
              cell: (e: RiskControlCriticalityDisplayType) => (<Button onClick={() => {
                removeRiskFromControlSet(parseInt(e.control_id),
                  risk.acs_risk_dim_sk,
                  e.criticality);
              }}>Remove</Button>),
              isRowHeader: true,
              width: 200,
              maxWidth: 210
            },
          ]}
          columnDisplay={[
            { id: "control_name", visible: true },
            { id: "owner_name", visible: true },
            { id: "criticality_name", visible: true },
            { id: "description", visible: true },
            { id: "remove", visible: true },
          ]}
          enableKeyboardNavigation
          items={itemsFromUseCollection}
          loadingText="Loading controls"
          wrapLines={true}
        />
        <div>
          {!isAddingNewControl &&
            <Button onClick={() => {
              setIsAddingNewControl(true);
            }}>Add control</Button>
          }
          {isAddingNewControl && <ColumnLayout columns={3}>
            <Box>
              <Select
                selectedOption={selectedControlCategory}
                placeholder="Select"
                onChange={({ detail }) => {
                  setSelectedControlCategory({ label: detail.selectedOption.label!, value: detail.selectedOption.value! });
                  if (detail.selectedOption.value == "-1") {
                    setEnableSubmitButton(false)
                  }
                }}
                options={controlCategoryOptions}
              />
            </Box>
            {selectedControlCategory.value !== "-1" && <Box>
              <Select
                selectedOption={selectedControl}
                placeholder="Select"
                onChange={({ detail }) => {
                  setSelectedControl({ label: detail.selectedOption.label!, value: detail.selectedOption.value! });
                  if (detail.selectedOption.value == "-1") {
                    setEnableSubmitButton(false)
                  }
                }}
                options={controlOptions}
              />
            </Box>
            }
            {selectedControl.value !== "-1" && <Box>
              <Select
                selectedOption={selectedCriticality}
                placeholder="Select"
                onChange={({ detail }) => {
                  setSelectedCriticality({ label: detail.selectedOption.label!, value: detail.selectedOption.value! });
                  setEnableSubmitButton(detail.selectedOption.value !== "-1");
                }}
                options={criticalityOptions}
              />
            </Box>
            }
          </ColumnLayout>}


        </div>
      </div>
    </Modal >
  );
};

export default ControlSetEditorForRiskModal;
