import { Box, Grid } from "@amzn/awsui-components-react";
import React from "react";
import SiteActivityLog from "./SiteActivityLog";
import SiteNotes from "./SiteNotes";

const SiteLogs = () => {
    return (
        <Grid
            gridDefinition={[
                { colspan: 4 },
                { colspan: 8 }
            ]}
        >
            <Box>
                <SiteNotes />
            </Box>
            <Box>
                <SiteActivityLog />
            </Box>
        </Grid>
    );
}

export default SiteLogs;