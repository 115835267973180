import { createContext, Dispatch, useReducer } from "react";
import { ReducerAction, ControlCriticalitiesByRiskType, RiskControlCriticalityDBType } from "src/types";

import useSecuredFetch from "./useSecuredFetch";
import { SESSION_TIMEOUT_ERROR } from "../constants"
import { getBaseUrl } from "src/utils";

/*
This holds a dictionary of global controls sets by risk number as well as methods for adding or removing a control set.
*/

export type ControlSetContextType = {
  controlCriticalitiesByRisk: ControlCriticalitiesByRiskType;
  error: string | null;
  isFetching: boolean
  successMessage: string | null;
}

function Reducer(state: ControlSetContextType, action: ReducerAction) {
  switch (action.Type) {
    case "UPDATE_CONTROL_CRITICALITIES": {
      const temp = state.controlCriticalitiesByRisk;
      temp[action.Cargo.riskId] = action.Cargo.controlCriticalities.controlSet;
      return {
        ...state,
        controlCriticalitiesByRisk: temp,
        isFetching: false
      }
    }
    case "ADD_CONTROL_CRITICALITY_SUCCESS": {
      const temp = state.controlCriticalitiesByRisk;
      temp[action.Cargo.riskId] = [action.Cargo];    //..push(action.Cargo.controlCriticalities.controlSet);
      return {
        ...state,
        controlCriticalitiesByRisk: temp,
        successMessage: "Control added",
        isFetching: false
      }
    }
    case "HIDE_MESSAGES": {
      return {
        ...state,
        error: null,
        successMessage: null,
        isFetching: false
      }
    }
    case "ADD_CONTROL_CRITICALITY_ERROR": {
      return {
        ...state,
        error: action.Cargo,
        successMessage: null,
        isFetching: false
      }
    }
    case "REMOVE_CONTROL_CRITICALITY_SUCCESS": {
      return {
        ...state,
        error: null,
        successMessage: "Threat removed",
        isFetching: false
      }
    }
    case "SET_IS_FETCHING_TRUE": {
      return {
        ...state,
        isFetching: true
      }
    }
  }

  return state;
}


export const ControlSetContext = createContext<{
  state: ControlSetContextType, dispatch: Dispatch<ReducerAction>,
  getControlSet: (riskNumber: number) => Promise<RiskControlCriticalityDBType[]>,
  addRiskToControlSet: (controlId: number, riskId: number, criticality: number) => Promise<boolean>,
  removeRiskFromControlSet: (controlId: number, riskId: number, criticality: number) => Promise<boolean>,
}>(
  {
    state: {
      controlCriticalitiesByRisk: {},
      error: "",
      isFetching: true,
      successMessage: null
    },
    dispatch: () => null,
    getControlSet: (_riskNumber: number) => Promise.resolve([{ riskId: "",
      control_id: "",
      criticality: 0,
      description: ""}]),
    addRiskToControlSet: (_controlId: number, _riskId: number, _criticality: number) => Promise.resolve(false),
    removeRiskFromControlSet: (_controlId: number, _riskId: number, _criticality: number) => Promise.resolve(false)
  }
);

export const ControlSetProvider = (props: { children: JSX.Element }) => {
  const { children } = props;
  const secureFetch = useSecuredFetch();
  const [state, dispatch] = useReducer(Reducer, {
    controlCriticalitiesByRisk: {},
    error: "",
    isFetching: true,
    successMessage: ""
  });

  const fetchControlSet = async (riskNumber: number) => {
    dispatch({ Type: "SET_IS_FETCHING_TRUE", Cargo: "" });
    const res = await secureFetch(`${getBaseUrl()}/globalControlSet?riskId=${riskNumber}`, 'GET');
    if (res.status === 401) {
      dispatch({ Type: "ADD_CONTROL_CRITICALITY_ERROR", Cargo: SESSION_TIMEOUT_ERROR });
      return [];
    }
    const controlSet = await res.json();
    dispatch({ Type: "UPDATE_CONTROL_CRITICALITIES", Cargo: { controlCriticalities: controlSet, riskId: riskNumber.toString() } });
    return controlSet;
  }

  const getControlSet = async (riskNumber: number) => {
    if (!state.controlCriticalitiesByRisk[riskNumber]) {
      return fetchControlSet(riskNumber);
    }
    return state.controlCriticalitiesByRisk[riskNumber];

  }

  const addRiskToControlSet = async (controlId: number, riskId: number, criticality: number) => {
    let res: { ok: boolean, status: number } = { ok: false, status: 0 };
    try {
      res = await secureFetch(`${getBaseUrl()}/globalControlSet`, 'POST', JSON.stringify({ controlId, riskId, criticality }));

      if (res.ok) {
        dispatch({ Type: "ADD_CONTROL_CRITICALITY_SUCCESS", Cargo: { controlId, riskId, criticality } });
        fetchControlSet(riskId);
      } else if (res.status == 401) {
        dispatch({ Type: "ADD_CONTROL_CRITICALITY_ERROR", Cargo: "Session timed out and is being auto-refreshed.  Please wait a few seconds and try again." });
      }
    }
    catch {
      dispatch({ Type: "ADD_CONTROL_CRITICALITY_ERROR", Cargo: "Unknown error" });
    }

    return res.ok;
  }

  const removeRiskFromControlSet = async (controlId: number, riskId: number, criticality: number) => {
    let res: { ok: boolean, status: number } = { ok: false, status: 0 };
    try {
      const res = await secureFetch(`${getBaseUrl()}/globalControlSet`, 'DELETE', JSON.stringify({ controlId, riskId, criticality }));

      if (res.ok) {
        dispatch({ Type: "REMOVE_CONTROL_CRITICALITY_SUCCESS", Cargo: { controlId, riskId, criticality } });
        fetchControlSet(riskId);
      } else if (res.status == 401) {
        dispatch({ Type: "ADD_CONTROL_CRITICALITY_ERROR", Cargo: "Session timed out and is being auto-refreshed.  Please wait a few seconds and try again." });
      }

    } catch {
      dispatch({ Type: "ADD_CONTROL_CRITICALITY_ERROR", Cargo: "Unknown error" });
    }

    return res.ok;
  }

  return (
    <ControlSetContext.Provider value={{ state, dispatch, getControlSet, addRiskToControlSet, removeRiskFromControlSet }}>
      {children}
    </ControlSetContext.Provider>
  );
};