import React, { useContext, useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import { Box, Button, SpaceBetween, Textarea } from "@amzn/awsui-components-react/polaris";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from "@amzn/awsui-collection-hooks";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { paginationLabels } from "../../../../constants";
import { ContentDisplayItem, SiteNoteDBType } from "../../../../types";
import { SelectedSiteContext } from "../../../useSelectedSite";
import { SiteLogContext } from "../../useSiteLog";
import EmptyState from "../../../EmptyState";
import { useLocalStorage } from "src/utils/use-local-storage";

const SiteNotes = () => {
  const { state, postNewNote } = useContext(SiteLogContext);
  const { selectedEntity } = useContext(SelectedSiteContext);
  const [isAddingNewNote, setIsAddingNewNote] = useState(false);
  const [newNoteText, setNewNoteText] = useState("");

  function getMatchesCountText(count: number | undefined) {
    return count === 1 ? `1 match` : `${count} matches`;
  }

  const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
      {
        id: "added_by",
        visible: true,
      },
      {
        id: "added_on",
        visible: true,
      },
      {
        id: "text",
        visible: true,
      },


    ]
  }

  const [preferences, setPreferences] = useLocalStorage<{
    pageSize: number,
    contentDisplay: readonly ContentDisplayItem[],
  }>("site_notes-table-preferences", DEFAULT_PREFERENCES);

  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(state.notes, {
    filtering: {
      empty: (
        <EmptyState
          title="No notes Found"
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),

    },
    pagination: { pageSize: preferences.pageSize },
    selection: {},
  });


  return (<SpaceBetween
    direction="vertical"
    size="m"
  ><Box>
      <div className="site-tab-content">
        <Table
          {...collectionProps}
          columnDefinitions={[
            {
              id: "added_by",
              header: "Added by",
              cell: (e: SiteNoteDBType) => e.added_by,
              isRowHeader: true,
            },
            {
              id: "added_on",
              header: "Added on",
              cell: (e: SiteNoteDBType) => e.added_on,
              isRowHeader: true,
            },
            {
              id: "text",
              header: "New value",
              cell: (e: SiteNoteDBType) => e.text,
              isRowHeader: true,
            },]}
          columnDisplay={preferences.contentDisplay}
          enableKeyboardNavigation
          items={itemsFromUseCollection}
          loadingText="Loading logs"
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No entries</b>
              </SpaceBetween>
            </Box>
          }
          filter={
            <TextFilter
              {...filterProps}
              countText={getMatchesCountText(filteredItemsCount)}
              filteringPlaceholder="Search notes..."
              filteringAriaLabel="Filter"
            />
          }
          header={
            <Header>{selectedEntity.site} notes</Header>
          }
          pagination={
            <Pagination {...paginationProps} ariaLabels={paginationLabels} />
          }
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={{
                pageSize: preferences.pageSize,
                contentDisplay: preferences.contentDisplay,
              }}
              onConfirm={({ detail }) => {
                const { pageSize, contentDisplay } = detail;
                if (typeof pageSize === "number" && contentDisplay) {
                  setPreferences({ pageSize, contentDisplay });
                }
              }}
              pageSizePreference={{
                title: "Select page size",
                options: [
                  { value: 10, label: "10 notes" },
                  { value: 20, label: "20 notes" },
                ],
              }}
              contentDisplayPreference={{
                options: [
                  {
                    id: "added_by",
                    label: "Added by",
                  },
                  {
                    id: "added_on",
                    label: "Added on",
                  },
                  {
                    id: "text",
                    label: "text",
                    alwaysVisible: true,
                  },
                ]
              }}

            />

          }
          wrapLines
        />
      </div>

    </Box>


    {!isAddingNewNote && <Box>
      <Button onClick={() => {
        setIsAddingNewNote(true);
      }}>Add a note</Button>
    </Box>
    }
    {isAddingNewNote &&

      <Box>
        <Textarea onChange={({ detail }) => setNewNoteText(detail.value)}
          value={newNoteText}
          placeholder="Add note here."></Textarea>
      </Box>
    }
    {isAddingNewNote &&
      <Box>
        <SpaceBetween
          direction="horizontal"
          size="s"
        >
          <Button onClick={() => {
            setIsAddingNewNote(false);
          }}>Cancel</Button>
          <Button onClick={() => {
            setIsAddingNewNote(false);
            postNewNote(newNoteText);
          }}>Save</Button>
        </SpaceBetween>
      </Box>
    }

  </SpaceBetween>);
}

export default SiteNotes;