import React, { useContext, useState } from "react";
import { Tabs } from "@amzn/awsui-components-react/polaris";
import SiteLandingPage from "./SiteLandingPage/SiteLandingPage";
import IncidentData from "./IncidentData";
import HealthCheck from "./HealthCheck";
import DefectsTable from "./SiteLandingPage/DefectsTable";
import SiteLogs from "./SiteLandingPage/ActivityLog/SiteLogs";
import { SiteContext } from "./useNewTabbedView";
import { SelectedSiteContext } from "../useSelectedSite";

const SiteContainer = () => {
  const { setInTabbedView } = useContext(SiteContext);
  const { setIsEditing } = useContext(SelectedSiteContext);
  const [activeTabId, setActiveTabId] = useState("site-landing-page");
  return (<Tabs
    activeTabId={activeTabId}
    onChange={(evt) => {
      setActiveTabId(evt.detail.activeTabId)
      if (evt.detail.activeTabId === "home") {
        setInTabbedView(false);
        setIsEditing(false);
      }
    }}
    tabs={[
      {
        label: "Home",
        id: "home",
        content: "",
      },
      {
        label: "Site information",
        id: "site-landing-page",
        content: <SiteLandingPage />,
      },
      {
        label: "Health check",
        id: "health-check",
        content: <HealthCheck />,
      },
     
      {
        label: "Incident data",
        id: "incident-data",
        content: <IncidentData />,
      },
      {
        label: "Assurance information",
        id: "Assurance information",
        content:  <DefectsTable />,
      },
      {
        label: "SPT & GRIS CSRT",
        id: "spt-and-gris",
        content: <div className="site-tab-content">Site prioritization tool and GRIS corporate site risk tool information displayed here</div>,
      },
      {
        label: "Activity log",
        id: "activity-log",
        content: <SiteLogs />,
      }
    ]}
  />)
}

export default SiteContainer;