import React, { useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Button, Container } from "@amzn/awsui-components-react";
import { ModalContext } from "../useModal";

const ReadOnlyRiskRegisterGetStartedModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);

  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("readOnlyRiskRegisterGetStartedModal")}
    >
       <Container header={<h3 className="center">Welcome to the global physical security threat register</h3>}>      
        <div className="control-details-modal">
        <div className="modal-body">
        The <strong>global threat register</strong> is a collection of the security threats Security Evaluations
        (SecEvals) tracks and reports globally.
        Individual threats are categorized thematically and designated a threat name based on
        common usage.
        To get started, select the threat category to view the individual threats and
        descriptions.  Select "View control set" to view the list of controls for the threat.
        <p>
        
        <strong>Threat-control set:</strong> A threat-control set is a designated group security controls that
        mitigate a given threat. A control criticality score is assigned to each, indicating the
        degree to which the control contributes to mitigating the threat. Controls with higher
        criticality scores indicate a greater role in mitigating the threat.
        </p>
        <p>
        For questions or comments please reach out to <Button href="mailto:SecEvals-Global@amazon.com" variant="inline-link">SecEvals-Global@amazon.com</Button>
        </p>
        </div>
      </div>
      </Container>

    </Modal >
  );
};

export default ReadOnlyRiskRegisterGetStartedModal;
